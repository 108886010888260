import { lighten } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Toolbar from '@mui/material/Toolbar';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ChatAppContext } from '../MessengerApp';
import UserAvatar from '../sidebars/main/UserAvatar';
import { getChatList, getChatListData, getChatListLoading, getChatListPaginationData, getIsSendMessageLoading, getSelectedConversationUserDetail, markAsReadChat, resetChatList, sendCommunication, setChatListPagination } from '../store/messengerAppSlice';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermission, openFileInWindow } from 'src/app/services/utils';
import { FeaturePermissions } from 'src/app/common/FeaturePermissions';
import { Card, CircularProgress, Grid, Input } from '@mui/material';
import { acceptFileTypeImageAndPDF } from 'src/app/common/constant';
import { fileUpload, getLoginUserData, getSelectedDocumentPath, selectIsImageLoading, setSelectedDocumentPath } from 'app/store/user/userSlice';
import { CancelIconButton, StyledBox, StyledDiv, StyledFilePreview } from '../../user/component/finance-detail-page/ChatWindow';
import CancelIcon from "@mui/icons-material/Cancel";
import FilePreview from 'app/shared-components/preview-file/FilePreview';
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { StyledTypography, StyledTypographyGrey } from 'app/shared-components/Styles/CustomStyle';
import ColoredSubmitButton from 'app/shared-components/Button/ColoredSubmitButton';
import PreviewMedia from 'app/shared-components/Dialog/PreviewMedia';
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from 'react-i18next';
import { isEmpty, throttle } from 'lodash';

type ChatPropsType = {
	className?: string;
};

/**
 * The Chat App.
 */
function Chat(props: ChatPropsType) {
	const { className } = props;
	const { t } = useTranslation("messenger");
	const { setMainSidebarOpen, setContactSidebarOpen } = useContext(ChatAppContext);
	const chatRef = useRef<HTMLDivElement>(null);
	const dispatch: any = useDispatch();
	const chat = useSelector(getChatListData);
	const [inputText, setInputText] = useState("");
	const isSendMessageLoading = useSelector(getIsSendMessageLoading);
	const [selectedFile, setSelectedFile] = useState<string | null>(null);
	const filePath = useSelector(getSelectedDocumentPath);
	const loginUserDetail = useSelector(getLoginUserData);
	const chatListPagination = useSelector(getChatListPaginationData);
	const [messages, setMessages] = useState([]);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const isChatLoading = useSelector(getChatListLoading);
	const [searchParams] = useSearchParams();
	const conversationId = searchParams.get('id');
	const userDetail = useSelector(getSelectedConversationUserDetail);
	const isPermissionToCreateCommunication = hasPermission(
		FeaturePermissions.ChatMessage.Create
	);
	const isPermissionToRead = hasPermission(FeaturePermissions.ChatMessage.Update);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const isImageLoading = useSelector(selectIsImageLoading);

	useEffect(() => {
		if (!!conversationId) {
			setMessages([]);
			setIsInitialLoad(true);

			// Reset pagination and chat list before fetching new conversation
			dispatch(
				setChatListPagination({
					pageIndex: 0,
					pageSize: 20, // or the desired default page size
				})
			);
			dispatch(resetChatList());

			dispatch(getChatList({ conversationId: conversationId }));
		}
	}, [conversationId]);

	useEffect(() => {
		setMessages(chat);
		if (isEmpty(chat) || !isPermissionToRead) return;
	  
		if (chat.some((item: any) => {
		  const isUnread = item.senderId !== loginUserDetail?.id && !item.readAt;
		  return isUnread;
		})) {
		  dispatch(markAsReadChat({ conversationId: conversationId }));
		}
	  }, [chat]);


	useEffect(() => {
		return () => {
			dispatch(setSelectedDocumentPath(null));
		};
	}, [conversationId]);

	useEffect(() => {
		if (filePath) {
			setSelectedFile(filePath);
		}
	}, [filePath]);



	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files[0];

		if (file) {
			dispatch(fileUpload(file));
		}
	};

	const handleRemove = () => {
		setSelectedFile(null);
	};

	const handleAttachmentClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleMessageSend = () => {
		if (inputText.trim() || selectedFile) {
			const newMessage = {
				conversationId: conversationId,
				messageText: inputText.trim(),
				fileURL: selectedFile
			};
			dispatch(sendCommunication(newMessage)).then(
				(success) => {
					if (success) {
						setInputText("");
						setSelectedFile(null);

						// Scroll to bottom after the message is added
						setTimeout(() => {
							scrollToBottom();
						});
					}
				}
			);
		}
	};

	const scrollToBottom = () => {
		if (chatRef.current) {
			chatRef.current.scrollTop =
				chatRef.current.scrollHeight;
		}
	};

	// For The Load More Data When Scroll ON Top
	useEffect(() => {
		const handleScroll = throttle(() => {
			if (!chatRef.current) return;
			const { scrollTop, scrollHeight, clientHeight } = chatRef.current;

			if (scrollTop === 0) {
				const previousScrollHeight = scrollHeight;
				setIsInitialLoad(false);
				// Fetch more messages on scroll to top
				if (chatListPagination.totalCount > chat.length) {
					dispatch(
						setChatListPagination({
							pageIndex: chatListPagination.pageIndex + 1,
							pageSize: chatListPagination.pageSize,
						})
					);
					dispatch(getChatList({ conversationId })).then(() => {
						//Info: Adjust scrollTop after new data is appended
						setTimeout(() => {
							if (chatRef.current) {
								const newScrollHeight = chatRef.current.scrollHeight;
								chatRef.current.scrollTop = newScrollHeight - previousScrollHeight;
							}
						});
					});
				}
			}
		}, 1000); // Throttled scroll handling

		if (isInitialLoad && messages.length > 0) {
			// Scroll to bottom only on initial load
			scrollToBottom();
			setIsInitialLoad(false); // Set to false after initial scroll
		}

		const pageNode = chatRef.current;
		if (pageNode) {
			pageNode.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (pageNode) {
				pageNode.removeEventListener("scroll", handleScroll);
			}
		};
	}, [dispatch, messages, chatListPagination]);

	const renderMessageContent = (message) => {
		return (
			<>
				<div>
					{message.fileURL && (
						<Paper variant="outlined" className="p-6 mb-10 flex items-start">
							<>
								<PreviewMedia filePath={message.fileURL}>
									<FilePreview fileUrl={message.fileURL} />
								</PreviewMedia>
								<IconButton
									className="top-0 right-0"
									onClick={() => openFileInWindow(message.fileURL)}
								>
									<DownloadIcon />
								</IconButton>
							</>
						</Paper>
					)}
					{message.messageText && (
						<StyledTypography variant="body2" className='!font-normal'>
							{message.messageText}
						</StyledTypography>
					)}
				</div>
			</>
		);
	};

	return (
		<>
			<Box
				className="w-full border-b-1"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? lighten(theme.palette.background.default, 0.4)
							: lighten(theme.palette.background.default, 0.02)
				}}
			>
				<Toolbar className="flex items-center justify-between px-16 w-full">
					<div className="flex items-center">
						<IconButton
							aria-label="Open drawer"
							onClick={() => setMainSidebarOpen(true)}
							className="flex lg:hidden"
							size="large"
						>
							<FuseSvgIcon>heroicons-outline:chat</FuseSvgIcon>
						</IconButton>
						<div
							className="flex items-center cursor-pointer"
							onClick={() => {
								setContactSidebarOpen(true);
							}}
							onKeyDown={() => setContactSidebarOpen(true)}
							role="button"
							tabIndex={0}
						>
							<UserAvatar
								className="relative mx-8"
								user={userDetail}
							/>
							<Typography
								color="inherit"
								className="text-16 font-semibold px-4"
							>
								{userDetail?.fullName}
							</Typography>
						</div>
					</div>
				</Toolbar>
			</Box>

			<div className="flex flex-auto h-full min-h-0 w-full">
				<div className={clsx('flex flex-1 z-10 flex-col relative', className)}>
					<div
						ref={chatRef}
						className="flex flex-1 flex-col overflow-y-auto mb-10"
					>
						{isChatLoading && (
							<Box className="flex justify-center items-center absolute top-0 w-full z-10">
								<CircularProgress size={24} color="secondary" />
							</Box>
						)}
						{messages?.length > 0 &&
							messages?.map((message, index) => (
								<Grid item xs={12} key={index} className='!p-0'>
									<StyledDiv sender={loginUserDetail?.id} personaldataname={message.senderId} className='pl-8 pr-2'>
										<div className="max-w-[80%]">
											<div className="flex flex-col mb-4">
												<StyledBox
													sender={loginUserDetail?.id}
													personaldataname={message.senderId}
												>
													{renderMessageContent(message)}
												</StyledBox>
												<StyledTypographyGrey className={`text-12 !font-normal !italic ${message.senderId === loginUserDetail?.id ? 'mr-16' : 'ml-16'}`}>
													{message.created}
												</StyledTypographyGrey>
											</div>
										</div>
									</StyledDiv>
								</Grid>
							))}
					</div>
					{isPermissionToCreateCommunication && (
						<Grid item xs={12} className='mx-10 mb-10'>
							<input
								type="file"
								ref={fileInputRef}
								className="hidden"
								accept={acceptFileTypeImageAndPDF}
								onChange={handleFileChange}
							/>
							<Card className="w-full overflow-hidden">
								<Input
									className="p-24 w-full"
									classes={{ root: "text-14" }}
									placeholder={t("WRITING_MESSAGE")}
									multiline
									rows="4"
									margin="none"
									disableUnderline
									value={inputText}
									disabled={isSendMessageLoading}
									onChange={(e) => setInputText(e.target.value)}
								/>
								{isImageLoading && (
									<StyledFilePreview variant="outlined">
										<CircularProgress size={18} color="secondary" />
									</StyledFilePreview>
								)}
								{selectedFile && (
									<StyledFilePreview variant="outlined">
										<CancelIconButton
											onClick={handleRemove}
											disabled={isSendMessageLoading}
										>
											<CancelIcon />
										</CancelIconButton>
										<FilePreview fileUrl={selectedFile}></FilePreview>
									</StyledFilePreview>
								)}
								<Box className="card-footer flex items-center flex-row border-t-1 px-24 py-12">
									<div className="flex flex-1 items-center">
										<IconButton
											onClick={handleAttachmentClick}
											disabled={isSendMessageLoading}
										>
											<AttachFileOutlinedIcon />
										</IconButton>
										<StyledTypographyGrey
											variant="body2"
											className="text-12 font-400 italic tracking-wide"
										>
											({t("SUPPORT_FILE")}: {acceptFileTypeImageAndPDF})
										</StyledTypographyGrey>
									</div>

									<div>
										<ColoredSubmitButton
											onClick={handleMessageSend}
											disabled={!inputText.trim() && !selectedFile}
											text={t("SEND")}
											isLoading={isSendMessageLoading}
										/>
									</div>
								</Box>
							</Card>
						</Grid>
					)}
				</div>
			</div>
		</>
	);
}

export default Chat;
