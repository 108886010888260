const locale = {
  OTP_SEND_MESSAGE:
    "Kami telah menghantar kod pengesahan ke nombor telefon bimbit anda:",
  OTP_ERROR_MESSAGE: "OTP diperlukan dan mesti mengandungi 6 digit.",
  OTP_SHOULD_BE_NUMERIC: "OTP mesti mengandungi 6 digit nombor sahaja.",
  VERIFY_BUTTON: "Sahkan",
  RESEND_OTP: "Hantar semula OTP",
  RESEND_IN: "Hantar semula dalam",
  ENTER_OTP: "Masukkan OTP",
  RETAKE_SIGN: "Ambil Semula Tandatangan",
  SIGNATURE_REQUIRED: "Tandatangan diperlukan.",
  SUBMIT_SIGN: "Hantar Tanda Tangan",
  SIGNATURE: "Tandatangan",
};

export default locale;
