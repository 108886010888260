import { styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLinkAdapterPropsType } from '@fuse/core/NavLinkAdapter/NavLinkAdapter';
import UserAvatar from './UserAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { startConversion } from '../../store/messengerAppSlice';
import { getLoginUserData } from 'app/store/user/userSlice';
import { hasPermission } from 'src/app/services/utils';
import { FeaturePermissions } from 'src/app/common/FeaturePermissions';

type ExtendedListItemProps = NavLinkAdapterPropsType & {
	component: React.ElementType;
};

const StyledListItem = styled(ListItemButton)<any>(({ theme }) => ({
	'&.active': {
		backgroundColor: theme.palette.background.default
	}
}));

type ContactListItemProps = {
	item: any;
};

/**
 * The contact list item.
 */
function ContactListItem(props: ContactListItemProps) {
	const { item } = props;
	const loginUserData = useSelector(getLoginUserData);
	const dispatch: any = useDispatch();
	const navigate = useNavigate();

	const isPermissionToCreateConversation = hasPermission(FeaturePermissions.ChatMessage.Create);

	const handleContactClick = () => {
		if (!isPermissionToCreateConversation) return;

		const request_data = {
			userOneId: loginUserData.id,
			userTwoId: item.id
		};

		dispatch(startConversion(request_data)).then((response) => {
			if (response && response.id) {
				navigate(`/user/messenger/chat?id=${response.id}`);
			}
		});
	}

	return (
		<StyledListItem
			component="div"
			className="px-16 py-12 min-h-80"
			onClick={handleContactClick}
		>
			<UserAvatar user={item} />

			<ListItemText
				classes={{
					root: 'min-w-px px-16',
					primary: 'font-medium text-14',
					secondary: 'truncate'
				}}
				primary={item.fullName}
			/>
		</StyledListItem>
	);
}

export default ContactListItem;
