import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import _ from "@lodash";
import { Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import userSlice, {
  updateRegisteredUser,
  selectIsButtonLoading,
  addMember,
  getUserId,
  addMiniAgent,
} from "app/store/user/userSlice";
import {
  generateAndVerifyOTPType,
  languages,
  miniAgentRoleId,
  roleId,
  tenantId,
  titles,
} from "src/app/common/constant";
import { useTranslation } from "react-i18next";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import withReducer from "app/store/withReducer";
import commonPhoneNumberInputProps from "app/shared-components/Form/commonPhoneNumberInputProps";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import { removeLeadingZero } from "src/app/services/utils";
import { isEmpty } from "lodash";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import OtpVerification from "app/shared-components/OtpVerification/OtpVerification";
import themesConfig from "app/configs/themesConfig";
import {
  getIsOTPGenerateLoading,
  getIsOTPVerifyLoading,
  getIsVerificationDialogOpen,
  sendOTP,
  setVerificationDialogOpen,
  verifyOTP,
} from "app/store/application-form/applicationFormSlice";
import { emailValidation, phoneNumberValidation } from "src/app/services/ValidationHelper/validationHelpers";

interface FormModel {
  title: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  defaultLanguage: string;
  other?: string;
}

function UpdatePersonalInfoForm({
  personalInfo,
  miniAgent,
  setIsDirty = (value) => {}
}) {
  const { t } = useTranslation("user");
  const dispatch: any = useDispatch();
  const userId = useSelector(getUserId);
  const verificationDialogOpen = useSelector(getIsVerificationDialogOpen);
  const isButtonLoading = useSelector(selectIsButtonLoading);
  const isVerifyOTPLoading = useSelector(getIsOTPVerifyLoading);
  const isGenerateOTPLoading = useSelector(getIsOTPGenerateLoading);

  const schema = yup.object().shape({
    title: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    fullName: yup
      .string()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("FULL_NAME") })
      ),
    email: emailValidation(true),
    phoneNumber: phoneNumberValidation(true),
    defaultLanguage: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    other: yup.string().when(["title"], (title, schema) => {
      if (title[0] === "OTHER") {
        return schema.required(
          t("TEXT_FIELD_REQUIRED_VALIDATION", {
            fieldName: t("OTHER"),
          })
        );
      }
      return schema.notRequired();
    }),
  });

  const methods = useForm<FormModel>({
    mode: "onChange",
    resolver: yupResolver<any>(schema),
  });

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
  } = methods;
  const { isValid, errors, isDirty } = formState;

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty, setIsDirty]);

  useEffect(() => {
    if (!isEmpty(personalInfo)) {
      setValue("fullName", personalInfo.fullName || "");
      setValue("email", personalInfo.email);
      setValue("phoneNumber", personalInfo.phoneNumber);
      setValue("defaultLanguage", personalInfo.defaultLanguage || languages[1]);
      const title = personalInfo?.title || titles[0].id;
      if (!titles.map((n) => n.id).includes(title)) {
        setValue("title", "OTHER");
        setValue("other", title || "");
      } else {
        setValue("title", title);
        setValue("other", "");
      }
    }
  }, [personalInfo, setValue]);

  const onSubmit = (model: FormModel) => {
    const { other, ...rest } = model;
    const finalTitle = model.title === "OTHER" ? model.other : model.title;

    if (miniAgent) {
      const request_data = {
        ...rest,
        tenantId: tenantId,
        roleId: miniAgentRoleId,
        parentId: userId,
        title: finalTitle,
      };
      dispatch(addMiniAgent(request_data));
    } else if (personalInfo) {
      const formData = {
        ...rest,
        id: personalInfo.id,
        title: finalTitle,
      };
      dispatch(updateRegisteredUser(formData));
    } else {
      const request_data = {
        ...rest,
        tenantId: tenantId,
        roleId: roleId,
        parentId: userId,
        title: finalTitle,
      };
      dispatch(addMember(request_data));
    }
  };

  const handleNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    personalInfo ? handleSubmit(onSubmit)() : handleSendOtp();
  };

  const handleVerificationDialogClose = () => {
    dispatch(setVerificationDialogOpen(false));
  };

  const handleSendOtp = () => {
    dispatch(
      sendOTP({
        mobile: getValues("phoneNumber"),
        otpType: miniAgent
          ? generateAndVerifyOTPType?.AddAgent
          : generateAndVerifyOTPType?.AddCustomer,
      })
    );
  };

  const handleFinalSubmit = (data) => {
    const request_data = {
      mobile: getValues("phoneNumber"),
      otp: data.otp,
      OTPType: miniAgent
        ? generateAndVerifyOTPType?.AddAgent
        : generateAndVerifyOTPType?.AddCustomer,
    };
    // Call verifyOTP to validate the OTP input
    dispatch(verifyOTP(request_data))
      .then((result) => {
        // If OTP is verified, submit the form
        handleSubmit(onSubmit)();
      })
      .catch((error) => {
        // Handle OTP verification failure (e.g., show an error message)
        console.error("OTP verification failed", error);
      });
  };

  const verificationComponent = (
    <OtpVerification
      color={themesConfig.default.palette.common.black}
      onOtpSubmit={handleFinalSubmit}
      loading={isVerifyOTPLoading || isButtonLoading}
      onResendOtp={handleSendOtp}
      isLogin={false}
      phoneNumber={getValues("phoneNumber")}
    />
  );

  return (
    <Box data-test-id="add-member-personal-info-form">
      <Paper variant="outlined">
        <FormProvider {...methods}>
          <form
            className="flex flex-col justify-center w-full p-20"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={2}>
                <Controller
                  name="title"
                  control={control}
                  defaultValue={titles[0].id}
                  render={({ field }) => (
                    <CustomAutocomplete
                      label={t("TITLE")}
                      options={titles?.map((item) => ({
                        label: t(item.label),
                        id: item.id,
                      }))}
                      value={field.value}
                      required
                      onChange={(value: any) => {
                        if (value && value.id) {
                          field.onChange(value.id);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      error={errors.title && t(errors.title.message)}
                      data-test-id="add-member-personal-info-title-input-field"
                    />
                  )}
                />
              </Grid>
              {watch("title") === "OTHER" && (
                <Grid item xs={12} sm={9} md={2}>
                  <Controller
                    name="other"
                    control={control}
                    defaultValue={titles[0].id}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("OTHER")}
                        fullWidth
                        required
                        value={field.value}
                        onChange={field.onChange}
                        error={errors?.other?.message}
                        inputProps={{
                          "data-test-id": "add-member-personal-info-other-title-input-field", // Add the data-testid attribute here
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              {/* Full name */}
              <Grid
                item
                xs={12}
                sm={9}
                md={watch("title") === "OTHER" ? 8 : 10}
              >
                <Controller
                  name="fullName"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("NAME")}
                      fullWidth
                      required
                      value={field.value || ""}
                      onChange={field.onChange}
                      error={errors?.fullName?.message}
                      inputProps={{
                        "data-test-id": "add-member-personal-info-name-input-field", // Add the data-testid attribute here
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("EMAIL")}
                      fullWidth
                      required
                      value={field.value || ""}
                      onChange={field.onChange}
                      error={errors?.email?.message}
                      inputProps={{
                        "data-test-id": "add-member-personal-info-email-input-field", // Add the data-testid attribute here
                      }}
                    />
                  )}
                />
              </Grid>

              {/* phone number */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label={t("MOBILE_NUMBER")}
                      type="number"
                      fullWidth
                      required
                      disabled={!!personalInfo && !!personalInfo?.phoneNumber}
                      value={field.value || ""}
                      InputProps={commonPhoneNumberInputProps}
                      onChange={(value: any) => {
                        field.onChange(removeLeadingZero(value));
                      }}
                      error={errors?.phoneNumber?.message}
                      inputProps={{
                        "data-test-id": "add-member-personal-info-phoneNumber-input-field", // Add the data-testid attribute here
                      }}
                    />
                  )}
                />
              </Grid>

              {/* default language */}
              <Grid item xs={12} sm={6} md={4}>
                <Controller
                  name="defaultLanguage"
                  control={control}
                  defaultValue={languages[0]}
                  render={({ field }) => (
                    <CustomAutocomplete
                      label={t("DEFAULT_LANGUAGE")}
                      options={languages}
                      onChange={(selectedValue) =>
                        field.onChange(selectedValue)
                      }
                      value={field.value}
                      error={
                        errors.defaultLanguage &&
                        t(errors.defaultLanguage.message)
                      }
                      data-test-id="add-member-personal-info-language-input-field"
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* Navigation Buttons */}
            <Box
              sx={{ mt: 2 }}
              className={personalInfo ? "text-right" : ""}
            >
              <div>
                <ColoredSubmitButton
                  onClick={handleNext}
                  disabled={
                    !isValid ||
                    isButtonLoading ||
                    !isDirty ||
                    verificationDialogOpen
                  }
                  isLoading={isGenerateOTPLoading}
                  text={
                    personalInfo
                      ? t("SAVE")
                      : t("SAVE_AND_CONTINUE")
                  }
                  data-test-id="add-member-personal-info-submit-btn"
                />
              </div>
            </Box>
          </form>
        </FormProvider>
      </Paper>
      {verificationDialogOpen && (
        <CustomDialog
          open={verificationDialogOpen}
          onClose={handleVerificationDialogClose}
          content={{
            title: t("VERIFY_OTP"),
            component: verificationComponent,
          }}
          maxWidth="xs"
        />
      )}
    </Box>
  );
}

// export default RegisterMemberForm;
export default withReducer("user", userSlice)(UpdatePersonalInfoForm);
