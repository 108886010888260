const locale = {
  AGREE: "Setuju",
  DISAGREE: "Tidak Setuju",
  VERIFY_OTP: "Pengesahan",
  VIDEO_ENDED_MESSAGE: "Selepas Melengkapkan Video anda boleh menghantar permohonan anda.",
  SIGNATURE: "Tandatangan",
  SIGNATURE_INFO: "Sila tandatangan secara digital untuk mengesahkan ketepatan maklumat anda dan teruskan penyerahan.",
};

export default locale;
