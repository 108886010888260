import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "src/app/services/apiService";
import { showMessage } from "../fuse/messageSlice";
import history from "@history";
import {
  getApplicationDetails,
  getApplicationListData,
  getApplicationStatus,
  getMemberDetailsById,
  setApplicationList,
} from "../user/userSlice";
import { isEmpty } from "lodash";

interface FinanceApplicationState {
  currentStep: number;
  categoryList: any;
  productList: any;
  activeFinanceDetail: any;
  activeFinanceResponse: any;
  additionalInfo: any;
  productVariantList: any;
  userAddress: any;
  referralDetails: any;
  relativeDetails: any;
  nextOfKinDetails: any;
  financeId: string;
  relativeInfoId: string;
  employmentDetails: any;
  employmentId: string;
  incomeDetailsId: string;
  incomeDetails: any;
  totalRow: number;
  existingFinance: any;
  documentList: any;
  bankingDetails: any;
  bankingDetailsId: string;
  financeDetails: any;
  isAddressEditOpen: boolean;
  isEditProductDetailsDialogOpen: boolean;
  isEditPersonalDetailsDialogOpen: boolean;
  isEditSiblingDetailsDialogOpen: boolean;
  isEditEmploymentDetailsDialogOpen: boolean;
  isEditIncomeDetailsDialogOpen: boolean;
  isEditBankDetailsDialogOpen: boolean;
  isEditExistingFinanceDetailsDialogOpen: boolean;
  isEditReferralDetailsDialogOpen: boolean;
  isEditNextOfKinDetailsDialogOpen: boolean;
  isDocumentDialogOpen: boolean;
  stepCompletionStatus: boolean[];
  financeProductList: any;
  kycErrorMessage: string;
  kycSuccessMessage: string;
  kycSDKToken: string;
  loading: {
    isButtonLoading: boolean;
    isOTPGenerateLoading: boolean;
    generateFinanceApplicationPDFLoading: boolean;
    isExistingFinanceButtonLoading: boolean;
    iseKYCButtonLoading: boolean;
    iseKYCResultSuccess: boolean;
    iseKYCStartingLoading: boolean;
    isShowGuideline: boolean;
    isActiveFinanceApplicationDialogOpen: boolean;
    isActiveFinanceRedirectDialogOpen: boolean;
    isDocumentSignatureDialogOpen: boolean;
    isFinalSubmitSignatureDialogOpen: boolean;
  };
}

const getUserIdFromUrl = () => {
  return new URLSearchParams(window.location.search).get("userId");
};

export const generatePDF = (financeApplicationId) => async (dispatch) => {
  dispatch(setGenerateFinanceApplicationPDFLoading(true));
  try {
    const response: any = await api.get(
      `/v1/FinanceApplication/GenerateFinancePdf?Id=${financeApplicationId}&HasFinanceDetail=true`
    );

    if (response && response.result) {
      const link = document.createElement("a");
      link.href = response.result;
      link.download = "finance_application.pdf";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(showMessage({ message: "PDF Generated!", variant: "success" }));
    }
  } catch (error) {
    console.error(error);
    dispatch(showMessage({ message: error.message, variant: "error" }));
  } finally {
    dispatch(setGenerateFinanceApplicationPDFLoading(false));
  }
};

export const ApplyForFinance = (request_data) => async (dispatch, getState) => {
  try {
    dispatch(setIsButtonLoading(true));
    const currentStep = getCurrentStep(getState());
    const response: any = await api.post(
      `/v1/FinanceApplication`,
      request_data
    );
    if (response.status.toLowerCase() === "success") {
      dispatch(showMessage({ message: response.message, variant: "success" }));
      dispatch(setCurrentStep(currentStep + 1));
      dispatch(setFinanceId(response.result));
      history.push(
        `/application-form/${currentStep + 1}?userId=${
          request_data.userId
        }&id=${response.result}`
      );
    } else {
      dispatch(setActiveFinanceResponse(response));
      dispatch(setIsActiveFinanceRedirectDialogOpen(true));
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
  } finally {
    dispatch(setIsButtonLoading(false));
  }
};

export const updateUserFinanceDetailsInForm =
  (request_data, financeId, isEdit = false, documentSignature = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const financeListData = getApplicationListData(getState());
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/FinanceApplication/UpdateFinanceApplication`,
        request_data
      );
      dispatch(
        showMessage({
          message: documentSignature
            ? "Signature Add Successfully"
            : "User Finance Details Update Successfully",
          variant: "success",
        })
      );
      if (documentSignature) {
        const updatedFinanceList =
          !!financeListData &&
          financeListData?.map((item) => {
            if (item.id === financeId) {
              return {
                ...item,
                signature: request_data.signature,
              };
            }
            return item;
          });
        if (!isEmpty(updatedFinanceList)) {
          dispatch(setApplicationList(updatedFinanceList));
        }
        dispatch(setIsDocumentSignatureDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else if (isEdit) {
        dispatch(setProductDetailDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        const userId = getUserIdFromUrl();
        dispatch(getApplicationDetails(financeId));
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${financeId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const getCategory = () => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/Category`);
    const CategoryList = response.result.items.map((item) => ({
      id: item.id,
      name: item.categoryName,
    }));
    if (CategoryList) {
      dispatch(setCategoryList(CategoryList));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProductVariant = () => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/ProductVariant`);
    if (response && response.result) {
      dispatch(setProductVariantList(response.result.items));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProduct = (id: string) => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/Product?CategoryId=${id}`);
    if (response && response.result) {
      dispatch(setProductList(response.result.items));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getUserDocumentForFinanceForm =
  (financeId: string) => async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/UserDocuments?FinanceApplicationId=${financeId}`
      );
      const DocumentList = response.result.items.filter(
        (item) => item.financeApplicationId === financeId
      );

      if (DocumentList) {
        dispatch(setDocumentList(DocumentList));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getUserFinanceApplicationForFinanceForm =
  (id: string) => async (dispatch, getState) => {
    try {
      const response: any = await api.get(`/v1/FinanceApplication/${id}`);
      dispatch(setUserFinanceDetailsForFinanceForm(response.result));
    } catch (error) {
      if (error?.status === "error" && error?.type === "NotFoundException") {
        // Redirect to "my applications" if the application is not found
        history.push("/user/my-application");
      } 
      console.error(error);
    }
  };

export const getUserAddressForFinanceForm =
  (financeId: string, userId: string) => async (dispatch, getState) => {
    dispatch(setUserAddressForFinanceForm([]));
    try {
      const response: any = await api.get(
        `/v1/UserAddress?FinanceApplicationId=${financeId}`
      );
      let financeAddress = response.result.items.find(
        (item) => item.financeApplicationId === financeId
      );

      if (response.result.items && response.result.items.length === 0) {
        const response: any = await api.get(`/v1/UserAddress?UserId=${userId}`);
        financeAddress = response.result.items.find(
          (item) => item.isDefault === true
        );
      }

      if (financeAddress) {
        dispatch(setUserAddressForFinanceForm(financeAddress));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getUserDetailInFinanceForm =
  (financeId: string, userId: string) => async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/UserAdditionalInformation?financeApplicationId=${financeId}`
      );
      let userAdditionalInformationForFinance = response.result.items.find(
        (item) => item.financeApplicationId === financeId
      );

      if (response.result.items && response.result.items.length === 0) {
        const response: any = await api.get(
          `/v1/UserAdditionalInformation?UserId=${userId}`
        );
        userAdditionalInformationForFinance = response.result.items.find(
          (item) => item.isDefault === true
        );
        if (response.result.items && response.result.items.length === 0) {
          dispatch(getMemberDetailsById(userId));
        }
      }

      if (userAdditionalInformationForFinance) {
        dispatch(
          setUserAdditionalInfoInFinanceForm(
            userAdditionalInformationForFinance
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getRelativeDetails = (financeId: string) => async (dispatch) => {
  try {
    const response: any = await api.get(
      `/v1/UserRelativeDetail?FinanceApplicationId=${financeId}`
    );
    const items = response?.result?.items;
    if (Array.isArray(items) && items.length > 0) {
      const UserRelativeDetailsForFinance = items?.find(
        (item) => item.financeApplicationId === financeId
      );

      if (UserRelativeDetailsForFinance) {
        dispatch(setRelativeDetails(UserRelativeDetailsForFinance));
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const getReferralDetails = (financeId: string) => async (dispatch) => {
  try {
    const response: any = await api.get(
      `/v1/UserReferralDetail?FinanceApplicationId=${financeId}`
    );
    const items = response?.result?.items;
    if (Array.isArray(items) && items.length > 0) {
      const UserReferralDetailsForFinance = items.find(
        (item) => item.financeApplicationId === financeId
      );

      if (UserReferralDetailsForFinance) {
        dispatch(setReferralDetails(UserReferralDetailsForFinance));
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const getNextOfKinDetails = (financeId: string) => async (dispatch) => {
  try {
    const response: any = await api.get(
      `/v1/UserNextofkin?FinanceApplicationId=${financeId}`
    );
    const items = response?.result?.items;
    if (Array.isArray(items) && items.length > 0) {
      const UserNextOfKinDetailsForFinance = items?.find(
        (item) => item.financeApplicationId === financeId
      );

      if (UserNextOfKinDetailsForFinance) {
        dispatch(setNextOfKinDetails(UserNextOfKinDetailsForFinance));
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const getEmploymentDetails =
  (financeId: string) => async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/UserEmploymentDetail?FinanceApplicationId=${financeId}`
      );
      const items = response?.result?.items;
      if (Array.isArray(items) && items.length > 0) {
        const EmploymentDetailsForFinance = items?.find(
          (item) => item.financeApplicationId === financeId
        );

        if (EmploymentDetailsForFinance) {
          dispatch(setEmploymentDetail(EmploymentDetailsForFinance));
        } else {
          dispatch(setEmploymentDetail([]));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

export const getIncomeDetails =
  (financeId: string) => async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/UserIncomeDetail?FinanceApplicationId=${financeId}`
      );
      const items = response?.result?.items;
      if (Array.isArray(items) && items.length > 0) {
        const IncomeDetailsForFinance = items?.find(
          (item) => item.financeApplicationId === financeId
        );

        if (IncomeDetailsForFinance) {
          dispatch(setIncomeDetails(IncomeDetailsForFinance));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

export const getBankingDetails = (FinanceId) => async (dispatch, getState) => {
  try {
    const response: any = await api.get(
      `/v1/UserBankingDetail?FinanceApplicationId=${FinanceId}`
    );
    const items = response?.result?.items;
    if (Array.isArray(items) && items.length > 0) {
      const BankingDetailsForFinance = items?.find(
        (item) => item.financeApplicationId === FinanceId
      );

      if (BankingDetailsForFinance) {
        dispatch(setBankingDetails(BankingDetailsForFinance));
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const getExistingFinanceDetails =
  (financeId) => async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/UserExistingFinance?FinanceApplicationId=${financeId}`
      );
      const ExistingFinanceDetailsForFinance = response.result.items.filter(
        (item) => item.financeApplicationId === financeId
      );
      dispatch(setExistingFinance(ExistingFinanceDetailsForFinance));
    } catch (err) {
      console.error(err);
    }
  };

export const userAdditionalInfoInForm =
  (request_data, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.post(
        `/v1/UserAdditionalInformation`,
        request_data
      );
      if (response.status) {
        dispatch(
          showMessage({
            message: "Additional Information Added Successfully",
            variant: "success",
          })
        );
        if (isEdit) {
          dispatch(setPersonalDetailDialogOpen(false));
          dispatch(getApplicationDetails(request_data.financeApplicationId));
        } else {
          dispatch(
            setStepCompletionStatus({ index: currentStep, isComplete: true })
          );
          dispatch(setCurrentStep(currentStep + 1));
          const userId = getUserIdFromUrl();
          history.push(
            `/application-form/${currentStep + 1}?userId=${userId}&id=${
              request_data.financeApplicationId
            }`
          );
          dispatch(setUserAdditionalInfoInFinanceForm(request_data));
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const updateUserAdditionalInfoDetailsInForm =
  (request_data, FinanceId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/UserAdditionalInformation/UpdateUserAdditionalInformation`,
        request_data
      );

      dispatch(
        showMessage({
          message: "User  Details Update Successfully",
          variant: "success",
        })
      );
      if (isEdit) {
        dispatch(setPersonalDetailDialogOpen(false));
        dispatch(getApplicationDetails(FinanceId));
      } else {
        dispatch(
          setStepCompletionStatus({ index: currentStep, isComplete: true })
        );
        dispatch(setUserAdditionalInfoInFinanceForm(request_data));
        const userId = getUserIdFromUrl();
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${FinanceId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const userRelativeDetailsInForm =
  (request_data, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.post(
        `/v1/UserRelativeDetail`,
        request_data
      );
      if (response.status) {
        dispatch(
          showMessage({
            message: "Relative Detail Added Successfully",
            variant: "success",
          })
        );
        dispatch(setRelativeDetails(request_data));
        if (isEdit) {
          dispatch(setSiblingDetailEditDialogOpen(false));
          dispatch(getApplicationDetails(request_data.financeApplicationId));
        } else {
          dispatch(
            setStepCompletionStatus({ index: currentStep, isComplete: true })
          );
          const userId = getUserIdFromUrl();
          history.push(
            `/application-form/${currentStep + 1}?userId=${userId}&id=${
              request_data.financeApplicationId
            }`
          );
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const userReferralDetailsInForm =
  (request_data, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.post(
        `/v1/UserReferralDetail`,
        request_data
      );
      if (response.status) {
        dispatch(
          showMessage({
            message: "Referral Detail Added Successfully",
            variant: "success",
          })
        );
        dispatch(setReferralDetails(request_data));
        if (isEdit) {
          dispatch(setReferralDetailEditDialogOpen(false));
          dispatch(getApplicationDetails(request_data.financeApplicationId));
        } else {
          dispatch(
            setStepCompletionStatus({ index: currentStep, isComplete: true })
          );
          const userId = getUserIdFromUrl();
          history.push(
            `/application-form/${currentStep + 1}?userId=${userId}&id=${
              request_data.financeApplicationId
            }`
          );
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const userNextOfKinDetailsInForm =
  (request_data, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.post(`/v1/UserNextofkin`, request_data);
      if (response.status) {
        dispatch(
          showMessage({
            message: "Next of Kin Detail Added Successfully",
            variant: "success",
          })
        );
        if (isEdit) {
          dispatch(setNextOfKinDetailEditDialogOpen(false));
          dispatch(getApplicationDetails(request_data.financeApplicationId));
        } else {
          dispatch(
            setStepCompletionStatus({ index: currentStep, isComplete: true })
          );
          const userId = getUserIdFromUrl();
          history.push(
            `/application-form/${currentStep + 1}?userId=${userId}&id=${
              request_data.financeApplicationId
            }`
          );
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const updateUserRelativeDetailsInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/UserRelativeDetail/UpdateUserRelativeDetail`,
        request_data
      );

      dispatch(
        showMessage({
          message: "Relative  Details Update Successfully",
          variant: "success",
        })
      );
      dispatch(setRelativeDetails(request_data));
      if (isEdit) {
        dispatch(setSiblingDetailEditDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        dispatch(
          setStepCompletionStatus({ index: currentStep, isComplete: true })
        );
        const userId = getUserIdFromUrl();
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${financeId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const updateUserReferralDetailsInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/UserReferralDetail/UpdateUserReferralDetail`,
        request_data
      );

      dispatch(
        showMessage({
          message: "Referral Details Update Successfully",
          variant: "success",
        })
      );
      dispatch(setReferralDetails(request_data));
      if (isEdit) {
        dispatch(setReferralDetailEditDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        dispatch(
          setStepCompletionStatus({ index: currentStep, isComplete: true })
        );
        const userId = getUserIdFromUrl();
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${financeId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const updateUserNextOfKinDetailsInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/UserNextofkin/UpdateUserNextofkin`,
        request_data
      );

      dispatch(
        showMessage({
          message: "Next Of Kin Details Update Successfully",
          variant: "success",
        })
      );
      if (isEdit) {
        dispatch(setNextOfKinDetailEditDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        dispatch(
          setStepCompletionStatus({ index: currentStep, isComplete: true })
        );
        const userId = getUserIdFromUrl();
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${financeId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const userEmploymentDetailsInForm =
  (request_data, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.post(
        `/v1/UserEmploymentDetail`,
        request_data
      );
      if (response.status) {
        dispatch(
          showMessage({
            message: "Employment Detail Added Successfully",
            variant: "success",
          })
        );
        if (isEdit) {
          dispatch(setEmploymentDetailDialogOpen(false));
          dispatch(getApplicationDetails(request_data.financeApplicationId));
        } else {
          const userId = getUserIdFromUrl();
          dispatch(
            setStepCompletionStatus({ index: currentStep, isComplete: true })
          );
          history.push(
            `/application-form/${currentStep + 1}?userId=${userId}&id=${
              request_data.financeApplicationId
            }`
          );
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const updateUserEmploymentDetailsInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/UserEmploymentDetail/UpdateUserEmploymentDetail`,
        request_data
      );

      dispatch(
        showMessage({
          message: "Employment  Details Update Successfully",
          variant: "success",
        })
      );
      if (isEdit) {
        dispatch(setEmploymentDetailDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        dispatch(
          setStepCompletionStatus({ index: currentStep, isComplete: true })
        );
        const userId = getUserIdFromUrl();
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${financeId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const userIncomeDetailsInForm =
  (request_data: any, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.post(
        `/v1/UserIncomeDetail`,
        request_data
      );
      if (response.status) {
        dispatch(
          showMessage({
            message: "Income Details Added Successfully",
            variant: "success",
          })
        );
        if (isEdit) {
          dispatch(setIncomeDetailDialogOpen(false));
          dispatch(getApplicationDetails(request_data.financeApplicationId));
        } else {
          dispatch(
            setStepCompletionStatus({ index: currentStep, isComplete: true })
          );
          const userId = getUserIdFromUrl();
          history.push(
            `/application-form/${currentStep + 1}?userId=${userId}&id=${
              request_data.financeApplicationId
            }`
          );
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const updateUserIncomeDetailsInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/UserIncomeDetail/UpdateUserIncomeDetail`,
        request_data
      );
      dispatch(
        showMessage({
          message: "Income  Details Update Successfully",
          variant: "success",
        })
      );
      if (isEdit) {
        dispatch(setIncomeDetailDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        dispatch(
          setStepCompletionStatus({ index: currentStep, isComplete: true })
        );
        const userId = getUserIdFromUrl();
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${financeId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const userBankingDetailsInForm =
  (request_data, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.post(
        `/v1/UserBankingDetail`,
        request_data
      );
      if (response.status) {
        dispatch(
          showMessage({
            message: "Banking  Details Added Successfully",
            variant: "success",
          })
        );
        if (isEdit) {
          dispatch(setBankDetailDialogOpen(false));
          dispatch(getApplicationDetails(request_data.financeApplicationId));
        } else {
          dispatch(
            setStepCompletionStatus({ index: currentStep, isComplete: true })
          );
          const userId = getUserIdFromUrl();
          history.push(
            `/application-form/${currentStep + 1}?userId=${userId}&id=${
              request_data.financeApplicationId
            }`
          );
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const updateUserBankingDetailsInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/UserBankingDetail/UpdateUserBankingDetail`,
        request_data
      );
      dispatch(
        showMessage({
          message: "Banking  Details Update Successfully",
          variant: "success",
        })
      );
      if (isEdit) {
        dispatch(setBankDetailDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        dispatch(
          setStepCompletionStatus({ index: currentStep, isComplete: true })
        );
        const userId = getUserIdFromUrl();
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${financeId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const addExistingFinanceDetailsInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsExistingFinanceButtonLoading(true));

      const response: any = await api.post(
        "/v1/UserExistingFinance",
        request_data
      );
      if (response.status) {
        dispatch(
          showMessage({ message: response.message, variant: "success" })
        );
        if (isEdit) {
          dispatch(setExistingLoanDetailDialogOpen(false));
          dispatch(getApplicationDetails(financeId));
        } else {
          dispatch(getExistingFinanceDetails(financeId));
          dispatch(setExistingLoanDetailDialogOpen(false));
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsExistingFinanceButtonLoading(false));
    }
  };

export const updateExistingFinanceDetailsInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsExistingFinanceButtonLoading(true));

      const response: any = await api.put(
        "/v1/UserExistingFinance/UpdateUserExistingFinance",
        request_data
      );
      dispatch(
        showMessage({
          message: `Existing Finance Details Update  Successfully`,
          variant: "success",
        })
      );
      if (isEdit) {
        dispatch(setExistingLoanDetailDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        dispatch(getExistingFinanceDetails(financeId));
        dispatch(setExistingLoanDetailDialogOpen(false));
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsExistingFinanceButtonLoading(false));
    }
  };

export const deleteUserExistingFinance =
  (id: string, financeId: string) => async (dispatch, getState) => {
    try {
      const totalRow = getTotalRow(getState());
      const response: any = await api.delete(
        `/v1/UserExistingFinance?id=${id}`
      );
      dispatch(
        showMessage({
          message: "Finance Delete SuccessFully",
          variant: "success",
        })
      );
      dispatch(setTotalRow(totalRow - 1));
      dispatch(getExistingFinanceDetails(financeId));
      dispatch(getApplicationDetails(financeId));
    } catch (error) {
      console.error(error);
      dispatch(showMessage({ message: error.message, variant: "error" }));
    }
  };

export const UserAddressInForm =
  (request_data, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.post(`/v1/UserAddress`, request_data);
      if (response.status) {
        dispatch(
          showMessage({
            message: "Address Added Successfully",
            variant: "success",
          })
        );
        dispatch(setUserAddressForFinanceForm(request_data));
        if (isEdit) {
          dispatch(setAddressEditDialogOpen(false));
          dispatch(getApplicationDetails(request_data.financeApplicationId));
        } else {
          dispatch(
            setStepCompletionStatus({ index: currentStep, isComplete: true })
          );
          const userId = getUserIdFromUrl();
          history.push(
            `/application-form/${currentStep + 1}?userId=${userId}&id=${
              request_data.financeApplicationId
            }`
          );
        }
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const updateUserAddressInForm =
  (request_data, financeId, isEdit = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsButtonLoading(true));
      const currentStep = getCurrentStep(getState());
      const response: any = await api.put(
        `/v1/UserAddress/UpdateUserAddress`,
        request_data
      );
      dispatch(
        showMessage({
          message: "User  Address Update Successfully",
          variant: "success",
        })
      );
      dispatch(setUserAddressForFinanceForm(request_data));
      if (isEdit) {
        dispatch(setAddressEditDialogOpen(false));
        dispatch(getApplicationDetails(financeId));
      } else {
        dispatch(
          setStepCompletionStatus({ index: currentStep, isComplete: true })
        );
        const userId = getUserIdFromUrl();
        history.push(
          `/application-form/${
            currentStep + 1
          }?userId=${userId}&id=${financeId}`
        );
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const finalSubmitApplication = (request_data) => async (dispatch) => {
  try {
    dispatch(setIsButtonLoading(true));
    const response: any = await api.put(
      `/v1/FinanceApplication/SubmitFinanceApplication`,
      request_data
    );
    if (response && response.result) {
      history.push(`/user/my-application/${request_data.financeApplicationId}`);
      dispatch(setVerificationDialogOpen(false));
      dispatch(getApplicationStatus(request_data.financeApplicationId));
      dispatch(getApplicationDetails(request_data.financeApplicationId));
      dispatch(showMessage({ message: response.message, variant: "success" }));
      dispatch(setIsButtonLoading(false));
    }
  } catch (error) {
    console.error(error);
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setIsButtonLoading(false));
  }
};

export const sendOTP = (mobile) => async (dispatch) => {
  dispatch(setIsOTPGenerateLoading(true));
  try {
    const response: any = await api.post("/v1/User/GenerateOTP", mobile);
    if (response) {
      dispatch(setVerificationDialogOpen(true));
      dispatch(
        showMessage({
          message: "OTP sent in your mobile Number.",
          variant: "info",
        })
      );
      dispatch(setFinanceProcessDialogOpen(false));
      dispatch(setIsOTPGenerateLoading(false));
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setIsOTPGenerateLoading(false));
  }
};

export const verifyOTP = (request_data) => async (dispatch) => {
  dispatch(setIsOTPVerifyLoading(true));
  try {
    const response: any = await api.get(
      `/v1/User/VerifyOTP?Mobile=${request_data.mobile}&OTP=${request_data.otp}&OTPType=${request_data.OTPType}`
    );
    if (response) {
      dispatch(setIsOTPVerifyLoading(false));
      return true;
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setIsOTPVerifyLoading(false));
    return false;
  }
};

export const eKYCResult = (request_data) => async (dispatch) => {
  dispatch(setIseKYCButtonLoading(true));
  try {
    const response: any = await api.post(
      `/v1/UserEKYC/VerifyEncryptedData`,
      request_data
    );
    if (response && response.status.toLowerCase() === "success") {
      dispatch(setKYCSuccessMessage(response.message));
      dispatch(setIseKYCResultSuccess(true));
    }
    dispatch(setIseKYCButtonLoading(false));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setKYCErrorMessage(error.message));
    dispatch(setIseKYCResultSuccess(false));
    dispatch(setIseKYCButtonLoading(false));
  }
};

export const kycProcessStart = (applicationId) => async (dispatch) => {
  dispatch(setIseKYCStartLoading(true));
  try {
    const response: any = await api.get(
      `/v1/UserEKYC/GenerateToken?FinanceApplicationId=${applicationId}`
    );
    if (response && response.result) {
      dispatch(setKYCSDKToken(response.result));
      dispatch(setIsShowGuideline(true));
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
  } finally {
    dispatch(setIseKYCStartLoading(false));
  }
};

export const kycUsingMobile =
  (applicationId, Authorization) => async (dispatch) => {
    dispatch(setIseKYCStartLoading(true));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Authorization}`,
        },
      };
      const response: any = await api.get(
        `/v1/UserEKYC/GenerateToken?FinanceApplicationId=${applicationId}`,
        config
      );
      if (response && response.result) {
        dispatch(setKYCSDKToken(response.result));
        dispatch(setIsShowGuideline(true));
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
    } finally {
      dispatch(setIseKYCStartLoading(false));
    }
  };

export const eKYCResultForMobile =
  (request_data, Authorization) => async (dispatch) => {
    dispatch(setIseKYCButtonLoading(true));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Authorization}`,
        },
      };
      const response: any = await api.post(
        `/v1/UserEKYC/VerifyEncryptedData`,
        request_data,
        config
      );
      if (response && response.status.toLowerCase() === "success") {
        dispatch(setKYCSuccessMessage(response.message));
        dispatch(setIseKYCResultSuccess(true));
      }
      dispatch(setIseKYCButtonLoading(false));
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setKYCErrorMessage(error.message));
      dispatch(setIseKYCResultSuccess(false));
      dispatch(setIseKYCButtonLoading(false));
    }
  };

export const getFinanceProductList =
  ({ pageIndex = 0 } = {}) =>
  async (dispatch, getState) => {
    try {
      const response: any = await api.get(`v1/FinanceProduct`);
      if (response && response.result) {
        dispatch(setFinanceProductList(response.result.items));
      }
      // dispatch(setFinanceProductListLoading(false));
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      // dispatch(setFinanceProductListLoading(false));
      console.error(error);
    }
  };

export const getActiveFinanceDetailFromApi =
  () => async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/FinanceApplication/GetActiveFinanceDetail`
      );
      if (
        response &&
        response.result &&
        response.result.financeApplicationStatus.toLowerCase() ===
          "pendingkyc" &&
        response.result.ekycStatus.toLowerCase() !== "success"
      ) {
        dispatch(setIsActiveFinanceApplicationDialogOpen(true));
        dispatch(setActiveFinanceDetail(response.result));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getActiveFinanceDetail = (state) =>
  state.applicationForm.activeFinanceDetail;
export const getCompletionStatus = (state) =>
  state.applicationForm.stepCompletionStatus;
export const getIsDocumentDialogOpen = (state) =>
  state.applicationForm.isDocumentDialogOpen;
export const getIsProductDetailDialogOpen = (state) =>
  state.applicationForm.isEditProductDetailsDialogOpen;
export const getIsPersonalDetailDialogOpen = (state) =>
  state.applicationForm.isEditPersonalDetailsDialogOpen;
export const getIsSiblingDetailEditDialogOpen = (state) =>
  state.applicationForm.isEditSiblingDetailsDialogOpen;
export const getIsReferralDetailEditDialogOpen = (state) =>
  state.applicationForm.isEditReferralDetailsDialogOpen;
export const getIsNextOfKinDetailEditDialogOpen = (state) =>
  state.applicationForm.isEditNextOfKinDetailsDialogOpen;
export const getIsEmploymentDetailEditDialogOpen = (state) =>
  state.applicationForm.isEditEmploymentDetailsDialogOpen;
export const getIsAddressEditDialogOpen = (state) =>
  state.applicationForm.isAddressEditOpen;
export const getIsIncomeEditDialogOpen = (state) =>
  state.applicationForm.isEditIncomeDetailsDialogOpen;
export const getIsBankEditDialogOpen = (state) =>
  state.applicationForm.isEditBankDetailsDialogOpen;
export const getIsExistingLoanDetailEditDialogOpen = (state) =>
  state.applicationForm.isEditExistingFinanceDetailsDialogOpen;
export const getUserDocumentListInFinanceForm = (state) =>
  state.applicationForm.documentList;
export const getUserFinanceDetailsDataInFinanceForm = (state) =>
  state.applicationForm.financeDetails;
export const getUserAdditionalInfoDataInFinanceForm = (state) =>
  state.applicationForm.additionalInfo;
export const getUserAddressDataInFinanceForm = (state) =>
  state.applicationForm.userAddress;
export const getUserBankingDetailData = (state) =>
  state.applicationForm.bankingDetails;
export const getExistingFinanceDetailsData = (state) =>
  state.applicationForm.existingFinance;
export const getIncomeDetailsData = (state) =>
  state.applicationForm.incomeDetails;
export const getEmploymentDetailsData = (state) =>
  state.applicationForm.employmentDetails;
export const getUserFinanceId = (state) => state.applicationForm.financeId;
export const getRelativeDetailsData = (state) =>
  state.applicationForm.relativeDetails;
export const getReferralDetailsData = (state) =>
  state.applicationForm.referralDetails;
export const getNextOfKinDetailsData = (state) =>
  state.applicationForm.nextOfKinDetails;
export const getCurrentStep = (state) => state.applicationForm.currentStep;
export const getTotalRow = (state) => state.applicationForm.totalRow;
export const getCategoryList = (state) => state.applicationForm.categoryList;
export const getProductList = (state) => state.applicationForm.productList;
export const getProductVariantList = (state) =>
  state.applicationForm.productVariantList;
export const getFinanceProductListData = (state) =>
  state.applicationForm?.financeProductList;
export const getKYCErrorMessage = (state) =>
  state.applicationForm?.kycErrorMessage;
export const getKYCSuccessMessage = (state) =>
  state.applicationForm?.kycSuccessMessage;
export const getKYCSDKToken = (state) => state.applicationForm?.kycSDKToken;
export const getActiveFinanceResponse = (state) =>
  state.applicationForm?.activeFinanceResponse;

const setLoadingState = (key, value) => async (dispatch) => {
  dispatch(setLoading({ key, value }));
};

export const setIsButtonLoading = (value) =>
  setLoadingState("isButtonLoading", value);
export const setVerificationDialogOpen = (value) =>
  setLoadingState("isVerificationDialogOpen", value);
export const setFinanceProcessDialogOpen = (value) =>
  setLoadingState("isFinanceProcessDialogOpen", value);
export const setIsFinalSubmitSignatureDialogOpen = (value) =>
  setLoadingState("isFinalSubmitSignatureDialogOpen", value);
export const setGenerateFinanceApplicationPDFLoading = (value) =>
  setLoadingState("generateFinanceApplicationPDFLoading", value);
export const setIsExistingFinanceButtonLoading = (value) =>
  setLoadingState("isExistingFinanceButtonLoading", value);
export const setIseKYCButtonLoading = (value) =>
  setLoadingState("iseKYCButtonLoading", value);
export const setIseKYCResultSuccess = (value) =>
  setLoadingState("iseKYCResultSuccess", value);
export const setIseKYCStartLoading = (value) =>
  setLoadingState("iseKYCStartingLoading", value);
export const setIsShowGuideline = (value) =>
  setLoadingState("isShowGuideline", value);
export const setIsActiveFinanceApplicationDialogOpen = (value) =>
  setLoadingState("isActiveFinanceApplicationDialogOpen", value);
export const setIsActiveFinanceRedirectDialogOpen = (value) =>
  setLoadingState("isActiveFinanceRedirectDialogOpen", value);
export const setIsDocumentSignatureDialogOpen = (value) =>
  setLoadingState("isDocumentSignatureDialogOpen", value);
export const setIsOTPGenerateLoading = (value) =>
  setLoadingState("isOTPGenerateLoading", value);
export const setIsOTPVerifyLoading = (value) =>
  setLoadingState("isOTPVerifyLoading", value);

export const getLoadingStateFactory = (loader) => (state) => {
  return state.applicationForm.loading[loader] || false;
};

export const selectIsButtonLoading = getLoadingStateFactory("isButtonLoading");
export const getIsOTPGenerateLoading = getLoadingStateFactory(
  "isOTPGenerateLoading"
);
export const getIsOTPVerifyLoading = getLoadingStateFactory(
  "isOTPVerifyLoading"
);
export const getIsDocumentSignatureDialogOpen = getLoadingStateFactory(
  "isDocumentSignatureDialogOpen"
);
export const getIsVerificationDialogOpen = getLoadingStateFactory(
  "isVerificationDialogOpen"
);
export const getIsFinanceProcessDialogOpen = getLoadingStateFactory(
  "isFinanceProcessDialogOpen"
);
export const getIsFinalSubmitSignatureDialogOpen = getLoadingStateFactory(
  "isFinalSubmitSignatureDialogOpen"
);
export const getGenerateFinanceApplicationPDFLoading = getLoadingStateFactory(
  "generateFinanceApplicationPDFLoading"
);
export const getIsExistingFinanceButtonLoading = getLoadingStateFactory(
  "isExistingFinanceButtonLoading"
);
export const getIseKYCButtonLoading = getLoadingStateFactory(
  "iseKYCButtonLoading"
);
export const getIseKYCResultSuccess = getLoadingStateFactory(
  "iseKYCResultSuccess"
);
export const getIseKYCStartLoading = getLoadingStateFactory(
  "iseKYCStartingLoading"
);
export const getIsShowGuideline = getLoadingStateFactory("isShowGuideline");
export const getIsActiveFinanceApplicationDialogOpen = getLoadingStateFactory(
  "isActiveFinanceApplicationDialogOpen"
);
export const getIsActiveFinanceRedirectDialogOpen = getLoadingStateFactory(
  "isActiveFinanceRedirectDialogOpen"
);

const initialState: FinanceApplicationState = {
  currentStep: 0,
  categoryList: [],
  productList: [],
  productVariantList: [],
  existingFinance: [],
  documentList: [],
  activeFinanceResponse: {},
  activeFinanceDetail: {},
  financeDetails: {},
  userAddress: {},
  additionalInfo: {},
  relativeDetails: {},
  referralDetails: {},
  nextOfKinDetails: {},
  employmentDetails: {},
  incomeDetails: {},
  employmentId: "",
  financeId: "",
  relativeInfoId: "",
  incomeDetailsId: "",
  totalRow: 0,
  bankingDetails: {},
  bankingDetailsId: "",
  isAddressEditOpen: false,
  isEditProductDetailsDialogOpen: false,
  isEditPersonalDetailsDialogOpen: false,
  isEditSiblingDetailsDialogOpen: false,
  isEditEmploymentDetailsDialogOpen: false,
  isEditIncomeDetailsDialogOpen: false,
  isEditBankDetailsDialogOpen: false,
  isEditExistingFinanceDetailsDialogOpen: false,
  isEditReferralDetailsDialogOpen: false,
  isEditNextOfKinDetailsDialogOpen: false,
  isDocumentDialogOpen: false,
  stepCompletionStatus: [],
  financeProductList: [],
  kycErrorMessage: "",
  kycSuccessMessage: "",
  kycSDKToken: "",
  loading: {
    isButtonLoading: false,
    isOTPGenerateLoading: false,
    generateFinanceApplicationPDFLoading: false,
    isExistingFinanceButtonLoading: false,
    iseKYCButtonLoading: false,
    iseKYCResultSuccess: false,
    iseKYCStartingLoading: false,
    isShowGuideline: false,
    isActiveFinanceApplicationDialogOpen: false,
    isActiveFinanceRedirectDialogOpen: false,
    isDocumentSignatureDialogOpen: false,
    isFinalSubmitSignatureDialogOpen: false,
  },
};

const applicationFormSlice = createSlice({
  name: "applicationForm",
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setStepCompletionStatus: (
      state,
      action: PayloadAction<
        | { index: number; isComplete: boolean }
        | { statuses: { index: number; isComplete: boolean }[] }
      >
    ) => {
      if ("statuses" in action.payload) {
        action.payload.statuses.forEach(({ index, isComplete }) => {
          state.stepCompletionStatus[index] = isComplete;
        });
      } else {
        const { index, isComplete } = action.payload;
        state.stepCompletionStatus[index] = isComplete;
      }
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setActiveFinanceResponse: (state, action: PayloadAction<any>) => {
      state.activeFinanceResponse = action.payload;
    },
    setFinanceId: (state, action: PayloadAction<string>) => {
      state.financeId = action.payload;
    },
    setAddressEditDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isAddressEditOpen = action.payload;
    },
    setSiblingDetailEditDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditSiblingDetailsDialogOpen = action.payload;
    },
    setReferralDetailEditDialogOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isEditReferralDetailsDialogOpen = action.payload;
    },
    setNextOfKinDetailEditDialogOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isEditNextOfKinDetailsDialogOpen = action.payload;
    },
    setProductDetailDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditProductDetailsDialogOpen = action.payload;
    },
    setPersonalDetailDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditPersonalDetailsDialogOpen = action.payload;
    },
    setEmploymentDetailDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditEmploymentDetailsDialogOpen = action.payload;
    },
    setIncomeDetailDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditIncomeDetailsDialogOpen = action.payload;
    },
    setBankDetailDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditBankDetailsDialogOpen = action.payload;
    },
    setExistingLoanDetailDialogOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isEditExistingFinanceDetailsDialogOpen = action.payload;
    },
    setIsDocumentDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isDocumentDialogOpen = action.payload;
    },
    setUserFinanceDetailsForFinanceForm: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.financeDetails = action.payload;
    },
    setUserAddressForFinanceForm: (state, action: PayloadAction<any[]>) => {
      state.userAddress = action.payload;
    },
    setUserAdditionalInfoInFinanceForm: (
      state,
      action: PayloadAction<any[]>
    ) => {
      state.additionalInfo = action.payload;
    },
    setCategoryList: (state, action: PayloadAction<any[]>) => {
      state.categoryList = action.payload;
    },
    setProductList: (state, action: PayloadAction<any[]>) => {
      state.productList = action.payload;
    },
    setProductVariantList: (state, action: PayloadAction<any[]>) => {
      state.productVariantList = action.payload;
    },
    setExistingFinance: (state, action: PayloadAction<any[]>) => {
      state.existingFinance = action.payload;
    },
    setDocumentList: (state, action: PayloadAction<any[]>) => {
      state.documentList = action.payload;
    },
    setRelativeDetails: (state, action: PayloadAction<any[]>) => {
      state.relativeDetails = action.payload;
    },
    setReferralDetails: (state, action: PayloadAction<any[]>) => {
      state.referralDetails = action.payload;
    },
    setNextOfKinDetails: (state, action: PayloadAction<any[]>) => {
      state.nextOfKinDetails = action.payload;
    },
    setIncomeDetails: (state, action: PayloadAction<any[]>) => {
      state.incomeDetails = action.payload;
    },
    setEmploymentDetail: (state, action: PayloadAction<any[]>) => {
      state.employmentDetails = action.payload;
    },
    setBankingDetails: (state, action: PayloadAction<any[]>) => {
      state.bankingDetails = action.payload;
    },
    setFinanceProductList: (state, action: PayloadAction<any[]>) => {
      state.financeProductList = action.payload;
    },
    setKYCErrorMessage: (state, action: PayloadAction<string>) => {
      state.kycErrorMessage = action.payload;
    },
    setKYCSuccessMessage: (state, action: PayloadAction<string>) => {
      state.kycSuccessMessage = action.payload;
    },
    setKYCSDKToken: (state, action: PayloadAction<string>) => {
      state.kycSDKToken = action.payload;
    },
    setActiveFinanceDetail: (state, action: PayloadAction<any>) => {
      state.activeFinanceDetail = action.payload;
    },
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: value,
      };
    },
    resetAll: (state: any) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setCurrentStep,
  setCategoryList,
  setProductList,
  setUserFinanceDetailsForFinanceForm,
  setProductVariantList,
  setUserAddressForFinanceForm,
  setUserAdditionalInfoInFinanceForm,
  setRelativeDetails,
  setReferralDetails,
  setNextOfKinDetails,
  setFinanceId,
  setEmploymentDetail,
  setIncomeDetails,
  setTotalRow,
  setExistingFinance,
  setDocumentList,
  setBankingDetails,
  setAddressEditDialogOpen,
  setProductDetailDialogOpen,
  setPersonalDetailDialogOpen,
  setSiblingDetailEditDialogOpen,
  setReferralDetailEditDialogOpen,
  setNextOfKinDetailEditDialogOpen,
  setEmploymentDetailDialogOpen,
  setIncomeDetailDialogOpen,
  setBankDetailDialogOpen,
  setExistingLoanDetailDialogOpen,
  setIsDocumentDialogOpen,
  setStepCompletionStatus,
  setLoading,
  resetAll,
  setFinanceProductList,
  setKYCErrorMessage,
  setKYCSuccessMessage,
  setKYCSDKToken,
  setActiveFinanceDetail,
  setActiveFinanceResponse,
} = applicationFormSlice.actions;
export default applicationFormSlice;
