import { styled } from '@mui/material/styles';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import MainSidebar from './sidebars/main/MainSidebar';
import Can from 'src/app/services/FeaturePermission/Can';
import { FeaturePermissions } from 'src/app/common/FeaturePermissions';
import { CommonBreadcrumbs } from 'app/shared-components/Breadcrumbs/CommonBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resetStore } from './store/messengerAppSlice';
import history from '@history';

type ChatAppContextType = {
	setMainSidebarOpen: (isOpen?: boolean) => void;
	setContactSidebarOpen: (isOpen?: boolean) => void;
};

export const ChatAppContext = createContext<ChatAppContextType>({
	setMainSidebarOpen: () => { },
	setContactSidebarOpen: () => { }
});

const Root = styled(FusePageSimple)(() => ({
	'& .FusePageSimple-content': {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 100%'
	}
}));

function MessengerApp() {
	const location = useLocation();
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
	const { t } = useTranslation("user");
	const dispatch: any = useDispatch();

	const [mainSidebarOpen, setMainSidebarOpen] = useState(!isMobile);
	const [contactSidebarOpen, setContactSidebarOpen] = useState(false);

	useEffect(() => {
		setMainSidebarOpen(!isMobile);
		if (isMobile) {
			setMainSidebarOpen(false);
		}
	}, [isMobile]);

	useEffect(() => {
		if (isMobile) {
			setMainSidebarOpen(false);
		}
	}, [location, isMobile]);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const id = searchParams.get('id');
		if (location.pathname === '/user/messenger/chat' && !id) {
			history.replace('/user/messenger');
		}
	}, [location]);

	// Store Empty When Return Two the Chat
	useEffect(() => {

		return () => {
			dispatch(resetStore());
		};
	}, [dispatch]);

	const ChatAppContextData = useMemo(
		() => ({
			setMainSidebarOpen,
			setContactSidebarOpen
		}),
		[setMainSidebarOpen, setContactSidebarOpen]
	);

	return (
		<Can
			permission={FeaturePermissions.ChatMessage.List}
			errorMessage="ACCESS_DENIED_VIEW_CHAT_MESSAGE"
		>
			<Container maxWidth="lg" className="pl-10 md:pl-76 pr-10 md:pr-76 mt-10">
				<CommonBreadcrumbs currentPageName={t("MESSENGER")} />
				<div className="min-h-[calc(100vh-400px)] h-[80vh] mt-10 !mb-40 border-1 border-grey-600">
					<ChatAppContext.Provider value={ChatAppContextData as ChatAppContextType}>
						<Root
							content={<Outlet />}
							leftSidebarContent={<MainSidebar />}
							leftSidebarOpen={mainSidebarOpen}
							leftSidebarOnClose={() => {
								setMainSidebarOpen(false);
							}}
							leftSidebarWidth={350}
							// rightSidebarContent={<ContactSidebar />} TODO: show User Detail
							rightSidebarOpen={contactSidebarOpen}
							rightSidebarOnClose={() => {
								setContactSidebarOpen(false);
							}}
							rightSidebarWidth={400}
							scroll="content"
						/>
					</ChatAppContext.Provider>
				</div>
			</Container>

		</Can>
	);
}

export default MessengerApp;
