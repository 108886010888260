import * as React from "react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { showMessage } from "app/store/fuse/messageSlice";
import { logoutUser, setUser } from "app/store/user/userSlice";
import { useAppDispatch } from "app/store";
import { AxiosError } from "axios";
import { UserType } from "app/store/user";
import jwtService from "./services/jwtService";
import { getNotification } from "../main/notification/store/notificationSlice";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";
import { hasPermission } from "../services/utils";
import { getActiveFinanceDetailFromApi } from "app/store/application-form/applicationFormSlice";
import { loginUserRoles } from "../common/constant";
import connection, { manageSignalRConnection } from "../services/signalRService";

/**
 * The AuthContext object is a React context object that provides authentication information to child components.
 */
const AuthContext = React.createContext({});

type AuthProviderProps = { children: ReactNode };

/**
 * The AuthProvider component is a wrapper component that provides authentication information to child components.
 */
function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useAppDispatch();
  const val = useMemo(() => ({ isAuthenticated }), [isAuthenticated]);
  const hasPermissionToViewNotification = hasPermission(
    FeaturePermissions.UserNotification.List
  );

  useEffect(() => {
    jwtService.on("onAutoLogin", () => {
      // dispatch(showMessage({ message: 'Signing in with JWT' }));

      /**
       * Sign in and retrieve user data with stored token
       */
      jwtService
        .signInWithToken()
        .then((user) => {
          success(user as UserType, "");
        })
        .catch((error: AxiosError) => {
          pass(error.message);
        });
    });

    jwtService.on("onLogin", (user: UserType) => {
      success(user, "");
    });

    jwtService.on("onLogout", () => {
      pass();

      dispatch(logoutUser());
    });

    jwtService.on("onAutoLogout", (message: string) => {
      pass(message);

      dispatch(logoutUser());
    });

    jwtService.on("onNoAccessToken", () => {
      pass();
    });

    jwtService.init();

    function success(user: UserType, message: string) {
      const promises = [dispatch(setUser(user))];
      
      // Start SignalR connection
      manageSignalRConnection(connection);

      // Time Out For Delay 15 sec to open the active finance application detail
      setTimeout(() => {
        // Check if the dialog should be skipped for today
        const lastShownDate = localStorage.getItem("pendingKYCDialogDate");
        const today = new Date().toLocaleDateString();

        // Check if the user is already on the KYC page
        const currentUrl = window.location.pathname;
        const kycPageUrl = `/user/my-application-kyc/`;
        const mobileKycUrl = "finance-mobile-kyc";
        const detailPageUrl = "/user/my-application/";

        // Conditionally create the promises array

        // Skip API call and dialog if already on the KYC page or if dialog is suppressed for today
        if (
          !currentUrl.includes(kycPageUrl) &&
          !currentUrl.includes(mobileKycUrl) &&
          !currentUrl.includes(detailPageUrl) &&
          user?.slug === loginUserRoles.Customer &&
          lastShownDate !== today
        ) {
          promises.push(dispatch(getActiveFinanceDetailFromApi()));
        }
      }, 15000);

      Promise.all(promises).then(() => {
        if (message) {
          dispatch(showMessage({ message }));
        }

        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      });
    }

    function pass(message?: string) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      // Stop SignalR connection
      connection.stop();

      // Clear "pendingKYCDialogDate" from localStorage on logout/autoLogout
      localStorage.removeItem("pendingKYCDialogDate");

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (hasPermissionToViewNotification) {
      dispatch(getNotification(false));
    }
  }, [hasPermissionToViewNotification]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
  ) : (
    <AuthContext.Provider value={val}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { useAuth, AuthProvider };
