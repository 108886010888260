import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import { Hidden } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import themesConfig from "app/configs/themesConfig";
import { useSelector } from "react-redux";
import { getSlug } from "app/store/user/userSlice";
import { pages } from "src/app/common/pageConfig";
import { pageFilter } from "src/app/services/utils";
import { loginUserRoles } from "src/app/common/constant";

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

const CustomBox = styled(Box)(({ theme }) => ({
  color: theme.palette.info.light,
  backgroundColor: theme.palette.secondary.main,
  textTransform: "capitalize",
  padding: "15px",
  alignItems: "center",
  "&.active": {
    backgroundColor: themesConfig.legacy.palette.background.paper,
    color: theme.palette.common.white,
  },
}));

const styles = {
  link: {
    textDecoration: "none",
    color: themesConfig.default.palette.text.primary,
  },
  activeLink: {
    color: themesConfig.default.palette.primary.light,
  },
};

function UserNavbar() {
  const { t } = useTranslation("user");
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [activePage, setActivePage] = React.useState<number | null>(1);
  const location = useLocation();
  const slug = useSelector(getSlug);

  const filteredPages = pageFilter(pages, slug);

  React.useEffect(() => {
    const currentPath = location.pathname;

    // Check if the current path matches any of the predefined URLs
    const matchIndex = filteredPages.findIndex((page) => {
      const url = `/user/${page.url.toLowerCase().replace(" ", "-")}`;
      return currentPath.startsWith(url);
    });

    // Special case for `my-application-kyc`
    if (currentPath.startsWith("/user/my-application-kyc")) {
      if (slug !== loginUserRoles.Customer) {
        navigate("/404"); // If the slug is not 'customer-user', navigate to 404
        return;
      }
    }
    // Handle normal pages
    else if (matchIndex === -1) {
      navigate("/404"); // Navigate to your 404 page
    } else {
      setActivePage(filteredPages[matchIndex].id);
    }
  }, [location.pathname, filteredPages, navigate, slug]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuItemClick = (id: number) => {
    setActivePage(id);
    handleCloseNavMenu();
  };

  return (
    <>
      <CustomAppBar position="static">
        <Container maxWidth="lg" className="pl-10 md:pl-64 pr-10 md:pr-76">
          <Toolbar disableGutters className="gap-40 items-center h-auto">
            <Hidden mdUp>
              <Box className="grow" />
              <Box>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  color={themesConfig.default.palette.info.light}
                >
                  {filteredPages.map((page) => (
                    <NavLink
                      to={`/user/${page.url}`}
                      style={({ isActive }) => ({
                        ...styles.link,
                        ...(isActive ? styles.activeLink : {}),
                      })}
                      key={page.id}
                    >
                      <MenuItem
                        onClick={() => handleMenuItemClick(page.id)}
                      >
                        <Box className="flex flex-nowrap gap-10">
                          <page.icon />
                          {t(page.title)}
                        </Box>
                      </MenuItem>
                    </NavLink>
                  ))}
                </Menu>
              </Box>
            </Hidden>
            <Hidden mdDown>
              <Box className="flex pt-10">
                {filteredPages.map((page) => (
                  <NavLink
                    to={`/user/${page.url}`}
                    style={{ textDecoration: "none" }}
                    key={page.id}
                  >
                    <CustomBox
                      onClick={() => handleMenuItemClick(page.id)}
                      className={`${
                        activePage === page.id ? "active" : ""
                      } flex gap-10 rounded-t-8  pb-20 cursor-pointer`}
                    >
                      <page.icon />
                      <Typography variant="body2" fontWeight={"bold"}>
                        {t(page.title)}
                      </Typography>
                    </CustomBox>
                  </NavLink>
                ))}
              </Box>
            </Hidden>
          </Toolbar>
        </Container>
      </CustomAppBar>
    </>
  );
}

export default UserNavbar;
