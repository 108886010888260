const locale = {
  ACCESS_DENIED_MINI_AGENT:
    "You are not authorize person to add Regional Manager.",
  ACCESS_DENIED_ADD_MEMBER: "You are not authorize person to add member.",
  ACCESS_DENIED_VIEW_MEMBER: "You are not authorize person to view member.",
  ACCESS_DENIED_VIEW_MINI_AGENT:
    "You are not authorize person to view Regional Manager.",
  ACCESS_DENIED_VIEW_APPLICATION:
    "You are not authorize person to view finance application.",
  ACCESS_DENIED: "Your are not authorize person to add finance application.",
  NOT_ALLOWED_NOTIFICATION:
    "You are not authorized person to view notification.",
  NOT_ALLOWED_VIEW_DETAIL: "You are not authorized person to view detail.",
  NOT_ALLOWED_VIEW_ADDRESS_DETAIL:
    "You are not authorized person to view Address detail.",
  NOT_ALLOWED_VIEW_DOCUMENTS:
    "You are not authorized person to view documents.",
  NOT_ALLOW_VIEW_COMMUNICATION:
    "You ae mot authorized person to view communications.",
  ACCESS_DENIED_VIEW_CHAT_MESSAGE:
    "You ae mot authorized person to view Chat Communication.",
};
export default locale;
