import moment from "moment";
export const date = moment().format("DD/MM/YYYY");

export const MAXYEAR = 10;
export const MINYEAR = 1;

export const MAX = 100000;
export const MIN = 1000;

export const stepsForApplicationForm = [
  { label: "PRODUCT_DETAILS", isComplete: false },
  { label: "PERSONAL_INFO", isComplete: false },
  { label: "ADDRESS_INFO", isComplete: false },
  { label: "SIBLINGS_DETAILS", isComplete: false },
  { label: "REFERRAL_DETAILS", isComplete: false },
  { label: "NEXT_OF_KIN_DETAILS", isComplete: false },
  { label: "EMPLOYMENT_DETAILS", isComplete: false },
  { label: "INCOME_DETAILS", isComplete: false },
  { label: "EXISTING_LOAN", isComplete: false },
  { label: "BANK_DETAILS", isComplete: false },
  { label: "UPLOAD_DOCUMENTS", isComplete: false },
];

//api Tenant id
export const tenantId = "247df315-f9a5-43ce-b15a-a9570804ad71";

// Role ID
export const roleId: string = "b24a113f-b85d-4249-8238-4d0c7dc01a43";

//agent Role ID
export const agentRoleId: string = "b7db3bac-4347-4c51-a3fa-3215d1b30b75";

//Mini agent Role Id
export const miniAgentRoleId: string = "bcf233e8-dc76-4b02-8f29-d5e6a18d6de3";

// language
export const languages: string[] = ["English", "Malay"];

// Titles
export const titles: any[] = [
  {
    id: "MR.",
    label: "MR",
  },
  {
    id: "MRS.",
    label: "MRS",
  },
  {
    id: "MISS.",
    label: "MISS",
  },
  {
    id: "OTHER",
    label: "OTHER",
  },
];

// Sexs
export const sexs: any[] = [
  {
    id: "MALE",
    label: "MALE",
  },
  {
    id: "FEMALE",
    label: "FEMALE",
  },
];

export const residentialLevel: any[] = [
  {
    id: "OWN",
    label: "OWN",
  },
  {
    id: "RENT",
    label: "RENT",
  },
  {
    id: "PARENTS",
    label: "PARENTS",
  },
  {
    id: "Other",
    label: "OTHER",
  },
];

// Job
export const jobTypes: any[] = [
  {
    id: "ARMY",
    label: "ARMY",
  },
  {
    id: "POLICE",
    label: "POLICE",
  },
  {
    id: "OTHER",
    label: "OTHER",
  },
];

export const relationship: any[] = [
  {
    id: "HUSBAND",
    label: "HUSBAND",
  },
  {
    id: "WIFE",
    label: "WIFE",
  },
];

export const accountType: any[] = [
  {
    id: "SAVING",
    label: "SAVING",
  },
  {
    id: "CURRENT",
    label: "CURRENT",
  },
];

export const qualification: any[] = [
  {
    id: "PRIMARY",
    label: "PRIMARY",
  },
  {
    id: "SECONDARY",
    label: "SECONDARY",
  },
  {
    id: "DIPLOMA",
    label: "DIPLOMA",
  },
  {
    id: "DEGREE",
    label: "DEGREE",
  },
  {
    id: "MASTERS",
    label: "MASTERS",
  },
  {
    id: "DOCTORATE DEGREE",
    label: "DOCTORATE_DEGREE",
  },
  {
    id: "PROFESSOR",
    label: "PROFESSOR",
  },
  {
    id: "Other",
    label: "OTHER",
  },
];

export const feedbacks = [
  {
    name: "Siddiq",
    feedback:
      "I'm really happy with the service! The cooperative support has been excellent too, great future!",
    rating: 5,
  },
  {
    name: "Rajeshwara",
    feedback:
      "I'm highly satisfied with the membership perks and the discounts on partner products are an added advantage. Definitely recommend!",
    rating: 5,
  },
  {
    name: "Nurul Hidayah",
    feedback:
      "The service has been great! The membership offers are useful. Really appreciate it!",
    rating: 5,
  },
  {
    name: "Wei Ming",
    feedback:
      "I'm quite satisfied with their service! The membership benefits are worth it, and the discounts on partner products are a nice bonus. Would recommend to others.",
    rating: 5,
  },
  {
    name: "Sundrachandran",
    feedback:
      "Being a member has been beneficial! Overall, a great value for the money! The cooperative support has been excellent.",
    rating: 5,
  },
];

export const videoUrl =
  "https://finance-public-assets.s3.ap-southeast-1.amazonaws.com/3195394-uhd_3840_2160_25fps.mp4";

export const acceptFileTypeImageAndPDF = ".pdf, .jpg, .png, .jpeg";

export const acceptFileTypeVideoAndAudio = ".mp3, .mp4, .wav";

export const durationRange = {
  minDuration: 1,
  maxDuration: 120,
};

export const documentSelectType = {
  finance: "Finance",
  agent: "Agent",
  admin: "Admin",
};

export const timerToCompleteKYC = 10; // add timer in minute

export const maritalStatusEnum = [
  {
    id: "MARRIED",
    label: "MARRIED",
  },
  {
    id: "SINGLE",
    label: "SINGLE",
  },
  {
    id: "WIDOW",
    label: "WIDOW",
  },
  {
    id: "DIVORCED",
    label: "DIVORCED",
  },
];

export const KYCResultStatusEnum = {
  success: "success",
  error: "error",
};

export const generateAndVerifyOTPType = {
  Login: "Login",
  Register: "Register",
  SubmitFinanceApplication: "SubmitFinanceApplication",
  AddCustomer: "AddCustomer",
  AddAgent: "AddAgent",
};

// ADD FORM STEP - USE FOR ADD MEMBER, AGENT
export const addFormStep = {
  PERSONAL_INFO: "PERSONAL_INFO",
  ADDITIONAL_INFORMATION: "ADDITIONAL_INFORMATION",
  ADDRESS_INFO: "ADDRESS_INFO",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
};

// Common function to get FAQ content
export const getFaqContent = (t) => [
  {
      id: 1,
      question: t("QUESTION_1"),
      answer: t("ANSWER_1"),
  },
  {
      id: 2,
      question: t("QUESTION_2"),
      answer: t("ANSWER_2"),
  },
  {
      id: 3,
      question: t("QUESTION_3"),
      answer: t("ANSWER_3"),
  },
  {
      id: 4,
      question: t("QUESTION_4"),
      answer: t("ANSWER_4"),
  },
  {
      id: 5,
      question: t("QUESTION_5"),
      answer: t("ANSWER_5"),
  },
  {
      id: 6,
      question: t("QUESTION_6"),
      answer: t("ANSWER_6"),
  },
  {
      id: 7,
      question: t("QUESTION_7"),
      answer: t("ANSWER_7"),
  },
  {
      id: 8,
      question: t("QUESTION_8"),
      answer: t("ANSWER_8"),
  },
  {
      id: 9,
      question: t("QUESTION_9"),
      answer: t("ANSWER_9"),
  },
];

export const financeApplicationStatusEnum = {
  Draft: "Draft",
  Submitted: "Submitted",
  Processing: "Processing",
  ActionRequired: "ActionRequired",
  Review: "Review",
  Approved: "Approved",
  ConditionalApproved: "ConditionalApproved",
  Rejected: "Rejected",
  KIV: "KIV",
  PendingKYC: "PendingKYC",
  DocumentSigning: "DocumentSigning",
  Disbursement: "Disbursement",
  Settled: "Settled",
  Active: "Active",
  Pending: "Pending"  // use For Status Chip
}

export const signalRFinanceHubEnum = {
  UpdateUserNotificationCount: "updateUserNotificationCount",
  FinanceApplicationCommunication: "financeApplicationCommunication",
  OneToOneChat: "oneToOneChat",
}

export const loginUserRoles = {
  Agent: "agent",
  MiniAgent: "mini-agent",
  Customer: "customer-user"
};