import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from "yup";
import FilePreview from '../preview-file/FilePreview';
import ColoredSubmitButton from '../Button/ColoredSubmitButton';
import SignatureComponent from '../signature-canvas/SignatureComponent';

const FinalSubmitSignature = ({ onSubmit }) => {
    const { t } = useTranslation("OtpVerification");

    const schema = yup.object().shape({
        signature: yup.string().required(t("SIGNATURE_REQUIRED")),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            signature: "",
        },
    });

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        trigger,
        formState: { errors, isValid },
    } = methods;

    const handleOtpSubmit = (data) => {
        onSubmit(data.signature);
    };

    const handleSaveSignature = (signatureData) => {
        setValue("signature", signatureData);
        trigger("signature");
    };

    const clearSignature = () => {
        setValue("signature", "");
        trigger("signature");
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleOtpSubmit)}>
                <div>
                    <Controller
                        name="signature"
                        control={control}
                        render={({ field }) => (
                            <Box className="flex flex-col gap-10 h-full min-h-200">
                                {getValues("signature") ? (
                                    <>
                                        <div className="border-dashed  border-2 border-black h-[200px]">
                                            <FilePreview fileUrl={getValues("signature")} />
                                        </div>
                                        <Box className="flex justify-end gap-10 items-center">
                                            <ColoredSubmitButton
                                                variant="outlined"
                                                onClick={clearSignature}
                                                text={t("RETAKE_SIGN")}
                                                data-test-id="signature-retake-btn"
                                            ></ColoredSubmitButton>
                                            <ColoredSubmitButton
                                                className="capitalize"
                                                text={t("SUBMIT_SIGN")}
                                                disabled={!isValid}
                                                data-test-id="signature-submit-btn"
                                            ></ColoredSubmitButton>
                                        </Box>
                                    </>
                                ) : (
                                    <SignatureComponent onSave={handleSaveSignature} />
                                )}
                            </Box>
                        )}
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default FinalSubmitSignature
