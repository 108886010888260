import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Breakpoint,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledTypographyGrey } from "../Styles/CustomStyle";

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  content: {
    title: string;
    description?: string;
    component: React.ReactNode;
  };
  maxWidth?: Breakpoint | false; // Define the type for maxWidth
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  content,
  maxWidth = "md",
}) => {
  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown"
  ) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>
        {content.title}
        {content.description && <StyledTypographyGrey variant='body2' className='mb-5 text-14 font-400 italic'>{content?.description}</StyledTypographyGrey>}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{content.component}</DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
