
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { FeaturePermissions } from 'src/app/common/FeaturePermissions';
import { loginUserRoles } from './constant';

export const pages = [
  {
    id: 1,
    icon: DashboardOutlinedIcon,
    title: 'DASHBOARD',
    url: 'dashboard',
    slug: [loginUserRoles.Customer, loginUserRoles.Agent, loginUserRoles.MiniAgent],
  },
  {
    id: 2,
    icon: PersonOutlineOutlinedIcon,
    title: 'PERSONAL_INFO',
    url: 'personal-info',
    permission: FeaturePermissions.User.View,
    slug: [loginUserRoles.Customer, loginUserRoles.Agent, loginUserRoles.MiniAgent],
  },
  {
    id: 3,
    icon: LocationOnOutlinedIcon,
    title: 'ADDRESS_INFO',
    url: 'address-information',
    permission: FeaturePermissions.UserAddress.List,
    slug: [loginUserRoles.Customer],
  },
  {
    id: 4,
    icon: GroupsOutlinedIcon,
    title: 'MEMBER',
    url: 'member',
    permission: FeaturePermissions.User.List,
    slug: [loginUserRoles.Agent, loginUserRoles.MiniAgent],
  },
  {
    id: 5,
    icon: ChecklistOutlinedIcon,
    title: 'MY_APPLICATION',
    url: 'my-application',
    permission: FeaturePermissions.FinanceApplication.List,
    slug: [loginUserRoles.Customer, loginUserRoles.Agent, loginUserRoles.MiniAgent],
  },
  {
    id: 6,
    icon: PeopleAltOutlinedIcon,
    title: 'MINI_AGENT',
    url: 'mini-agent',
    permission: FeaturePermissions.MiniAgent.List,
    slug: [loginUserRoles.Agent],
  },
  {
    id: 7,
    icon: TextsmsOutlinedIcon,
    title: 'MESSENGER',
    url: 'messenger',
    permission: FeaturePermissions.ChatMessage.List,
    slug: [loginUserRoles.Customer, loginUserRoles.Agent, loginUserRoles.MiniAgent],
  },
];
