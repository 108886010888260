import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import _ from '@lodash';

const StyledBadge = styled(Badge)<{ statuscolor?: string }>(({ theme, ...props }) => ({
	width: 40,
	height: 40,
	fontSize: 20,
	'& .MuiAvatar-root': {
		fontSize: 'inherit',
		color: theme.palette.text.secondary,
		fontWeight: 600
	},
	// '& .MuiBadge-badge': {
	// 	backgroundColor: props.statuscolor,
	// 	boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
	// 	'&::after': {
	// 		position: 'absolute',
	// 		top: 0,
	// 		left: 0,
	// 		width: '100%',
	// 		height: '100%',
	// 		borderRadius: '50%',
	// 		content: '""'
	// 	}
	// }
}));

type UserAvatarPropsType = {
	user: any;
	className?: string;
};

/**
 * The user avatar component.
 */
function UserAvatar(props: UserAvatarPropsType) {
	const { user, className } = props;

	return (
		// TODO: Show Status Color And Badge when Status add
		<StyledBadge
			className={className}
			overlap="circular"
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			variant="dot"
			// statuscolor={status?.color}
		>
		<Avatar
			src={user?.avatar}
			alt={user?.fullName}
			className="w-full h-full"
		>
			{user?.fullName ? user?.fullName[0] : ''}
		</Avatar>
	</StyledBadge>
	);
}

export default UserAvatar;
