import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import history from "@history";
import { setInitialSettings } from "app/store/fuse/settingsSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import settingsConfig from "app/configs/settingsConfig";
import { FuseSettingsConfigType } from "@fuse/core/FuseSettings/FuseSettings";
import { AppDispatchType, RootStateType } from "app/store/types";
import {
  Member,
  MiniAgent,
  Permission,
  Status,
  UserType,
  application,
} from "app/store/user";
import { PartialDeep } from "type-fest";
import { AxiosError } from "axios/index";
import jwtService from "../../auth/services/jwtService";
import createAppAsyncThunk from "../createAppAsyncThunk";
import api from "src/app/services/apiService";
import { agentRoleId, miniAgentRoleId, roleId } from "src/app/common/constant";
import {
  getCurrentStep,
  setCurrentStep,
  setUserFinanceDetailsForFinanceForm,
  setVerificationDialogOpen,
} from "../application-form/applicationFormSlice";
import { resetNotification } from "src/app/main/notification/store/notificationSlice";
import moment from "moment";

type AppRootStateType = RootStateType<userSliceType>;

/**
 * Sets the user data in the Redux store and updates the login redirect URL if provided.
 */
export const setUser = createAsyncThunk("user/setUser", (user: UserType) => {
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
  }

  return Promise.resolve(user);
});
export const setDisplayData = createAction<Partial<UserType["data"]>>(
  "user/setDisplayData"
);
/**
 * Updates the user's settings in the Redux store and returns the updated user object.
 */
export const updateUserSettings = createAppAsyncThunk(
  "user/updateSettings",
  async (
    settings: FuseSettingsConfigType,
    { dispatch, rejectWithValue, getState }
  ) => {
    const AppState = getState() as AppRootStateType;
    const { user } = AppState;

    const isUserGuest = selectIsUserGuest(AppState);

    if (isUserGuest) {
      return null;
    }

    const userRequestData = { data: { ...user.data, settings } } as UserType;

    try {
      const response = await jwtService.updateUserData(userRequestData);

      dispatch(showMessage({ message: "User settings saved with api" }));

      return response.data as UserType;
    } catch (error) {
      const axiosError = error as AxiosError;

      dispatch(showMessage({ message: axiosError.message }));

      return rejectWithValue(axiosError.message);
    }
  }
);

/**
 * Updates the user's shortcuts in the Redux store and returns the updated user object.
 */
export const updateUserShortcuts = createAppAsyncThunk(
  "user/updateShortucts",
  async (shortcuts: string[], { dispatch, getState, rejectWithValue }) => {
    const AppState = getState() as AppRootStateType;
    const { user } = AppState;

    const isUserGuest = selectIsUserGuest(AppState);

    if (isUserGuest) {
      return null;
    }

    const userRequestData = {
      data: { ...user.data, shortcuts },
    } as PartialDeep<UserType>;

    try {
      const response = await jwtService.updateUserData(userRequestData);

      dispatch(showMessage({ message: "User shortcuts saved with api" }));

      return response.data as UserType;
    } catch (error) {
      const axiosError = error as AxiosError;

      dispatch(showMessage({ message: axiosError.message }));

      return rejectWithValue(axiosError.message);
    }
  }
);

/**
 * Logs the user out and resets the Redux store.
 */
export const logoutUser =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const AppState = getState() as AppRootStateType;

    const isUserGuest = selectIsUserGuest(AppState);

    if (isUserGuest) {
      return null;
    }

    history.push({
      pathname: "/",
    });

    dispatch(setInitialSettings());
    dispatch(resetNotification());
    return Promise.resolve(dispatch(userLoggedOut()));
  };

export const getUserDetail = (id: string) => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/User/GetUserDetailById?Id=${id}`);
    dispatch(
      setDisplayData({
        displayName: response.result.fullName,
      })
    );
    dispatch(setUserData(response.result));
  } catch (error) {
    console.error(error);
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setUserData(null));
  }
};

export const getMemberDetailsById = (id: string) => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/User/GetUserDetailById?Id=${id}`);
    dispatch(setMemberData(response.result));
  } catch (error) {
    console.error(error);
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setMemberData(null));
  }
};

export const getUserAdditionalDetail = (id: string) => async (dispatch) => {
  try {
    const response: any = await api.get(
      `/v1/UserAdditionalInformation?UserId=${id}`
    );
    const defaultInfo = response.result.items.find(
      (item) => item.isDefault === true
    );
    dispatch(setUserAdditionalInfo(defaultInfo));
  } catch (error) {
    console.error(error);
  }
};

export const getUserAdditionalDetailById = (id: string) => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/UserAdditionalInformation/${id}`);
    if (response.result && response.result.items) {
      const defaultInfo = response.result.items.find(
        (item) => item.isDefault === true
      );
      dispatch(setUserAdditionalInfo(defaultInfo));
    }
  } catch (error) {
    console.error(error);
  }
};

export const additionalInfo =
  (request_data, isMemberDetail = false) =>
  async (dispatch, getState) => {
    const activeStep = getCurrentStepForMemberAdd(getState());
    try {
      dispatch(setIsButtonLoading(true));
      const response: any = await api.post(
        "/v1/UserAdditionalInformation",
        request_data
      );
      if (response.status) {
        if (activeStep === 1) {
          dispatch(setCurrentStepForMember(activeStep + 1));
        } else {
          if (isMemberDetail) {
            dispatch(getMemberDetailsById(request_data.userId));
          } else {
            dispatch(getUserDetail(request_data.userId));
          }
          dispatch(setIsEditAdditionalInfoDialogOpen(false));
        }
        dispatch(
          showMessage({ message: response.message, variant: "success" })
        );
        dispatch(setIsButtonLoading(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setIsButtonLoading(false));
      dispatch(showMessage({ message: error.message, variant: "error" }));
    }
  };

export const addMember =
  (request_data) =>
  async (dispatch, getState) => {
    const activeStep = getCurrentStepForMemberAdd(getState());
    dispatch(setIsButtonLoading(true));
    try {
      const response: any = await api.post("/v1/User", request_data);
      if (response.status) {
        dispatch(
          showMessage({
            message: "Member created successfully",
            variant: "success",
          })
        );
        dispatch(setIsButtonLoading(false));
        dispatch(setMemberUserId(response.result));
        dispatch(setCurrentStepForMember(activeStep + 1));
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setIsButtonLoading(false));
      console.error(error);
    } finally {
      dispatch(setVerificationDialogOpen(false));
    }
  };

export const addMiniAgent = (request_data) => async (dispatch, getState) => {
  dispatch(setIsButtonLoading(true));
  try {
    const activeStep = getCurrentStep(getState());
    const response: any = await api.post("/v1/User", request_data);
    if (response.status) {
      dispatch(
        showMessage({
          message: "Agent created successfully",
          variant: "success",
        })
      );
      dispatch(setIsButtonLoading(false));
      dispatch(setMemberUserId(response.result));
      dispatch(setCurrentStep(activeStep + 1));
    }
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setIsButtonLoading(false));
    console.error(error);
  } finally {
    dispatch(setVerificationDialogOpen(false));
  }
};

export const updateRegisteredUser =
  (request_data) => async (dispatch, getState) => {
    try {
      const userId = getUserId(getState());
      const memberId = getMemberUserId(getState());
      dispatch(setIsButtonLoading(true));
      const response: any = await api.put(`/v1/User/UpdateUser`, request_data);
      dispatch(setIsEditPersonalDialogOpen(false));
      if (memberId) {
        dispatch(getMemberDetailsById(memberId));
      } else {
        dispatch(getUserDetail(userId));
      }

      dispatch(
        showMessage({
          message: "Registered User Updated Successfully",
          variant: "success",
        })
      );
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
      dispatch(setVerificationDialogOpen(false));
    }
  };

export const updateUserAdditionalInfo =
  (request_data) => async (dispatch, getState) => {
    try {
      const userId = getUserId(getState());
      const memberId = getMemberUserId(getState());
      dispatch(setIsButtonLoading(true));
      const response: any = await api.put(
        `/v1/UserAdditionalInformation/UpdateUserAdditionalInformation`,
        request_data
      );
      dispatch(setIsEditAdditionalInfoDialogOpen(false));
      if (memberId) {
        dispatch(getMemberDetailsById(memberId));
      } else {
        dispatch(getUserDetail(userId));
      }
      dispatch(
        showMessage({
          message: "Additional Information Updated Successfully",
          variant: "success",
        })
      );
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const addressUpload =
  (request_data, isUseForFinance = false) =>
  async (dispatch, getState) => {
    const activeStep = getCurrentStepForMemberAdd(getState());
    try {
      dispatch(setIsButtonLoading(true));
      const response: any = await api.post("/v1/UserAddress", request_data);
      if (response.status) {
        if (activeStep === 2 && !isUseForFinance) {
          history.push("/user/member");
        } else if (isUseForFinance) {
          dispatch(setIsAddMemberDialogOpen(false));
        } else {
          dispatch(getUserAddress(request_data.userId));
          dispatch(setIsEditAddressDialogOpen(false));
        }
        dispatch(
          showMessage({ message: response.message, variant: "success" })
        );
        dispatch(setIsButtonLoading(false));
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setIsButtonLoading(false));
      console.error(error);
    }
  };

export const getUserAddress = (id: string) => async (dispatch) => {
  dispatch(setUserAddress([]));
  try {
    const response: any = await api.get(`/v1/UserAddress?UserId=${id}`);
    const defaultAddress = response.result.items.find(
      (item) => item.isDefault === true
    );
    if (defaultAddress) {
      dispatch(setUserAddress(defaultAddress));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateUserAddress =
  (request_data) => async (dispatch, getState) => {
    try {
      const userId = getUserId(getState());
      const memberId = getMemberUserId(getState());
      dispatch(setIsButtonLoading(true));
      const response: any = await api.put(
        `/v1/UserAddress/UpdateUserAddress`,
        request_data
      );
      dispatch(setIsEditAddressDialogOpen(false));
      if (memberId) {
        dispatch(getUserAddress(memberId));
      } else {
        dispatch(getUserAddress(userId));
      }
      dispatch(
        showMessage({
          message: "Address Updated Successfully",
          variant: "success",
        })
      );
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
    } finally {
      dispatch(setIsButtonLoading(false));
    }
  };

export const fileUpload = (file) => async (dispatch, getState) => {
  dispatch(setIsImageLoading(true));
  try {
    const formData: any = new FormData();
    formData.append("file", file);

    const response: any = await api.post("/v1/FileUpload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    dispatch(setSelectedDocumentPath(response.result));
    dispatch(setIsImageLoading(false));
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setIsImageLoading(false));
    console.error(error);
  }
};

export const getDocumentTypeList =
  ({ documentSelectType }) =>
  async (dispatch, getState) => {
    try {
      const response: any = await api.get(
        `/v1/DocumentType?Type=${documentSelectType}`
      ); // Use api.get to fetch document types
      const documentTypes = response.result.items.map((item) => ({
        id: item.id,
        name: item.name,
        require: item.isRequired,
        slug: item.slug,
      }));
      dispatch(setDocumentTypeList(documentTypes));
    } catch (error) {
      console.error(error);
    }
  };

export const getUserDocumentsById = (id) => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/UserDocuments?UserId=${id}`, {
      UserId: id,
    });
    const defaultDocument = response.result.items.filter(
      (item) => item.isDefault === true
    );
    dispatch(setDocumentsList(defaultDocument));
  } catch (error) {
    console.error(error);
  }
};

export const getApplicationList =
  ({ pageIndex }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setApplicationListLoading(true));
      const pagination = getPaginationData(getState());
      const pageIndexQuery = pageIndex || pagination.pageIndex;
      const filters = getFilters(getState());

      const queryParams = [
        pagination.pageSize && `PageSize=${pagination.pageSize}`,
        pageIndexQuery && `PageIndex=${pageIndexQuery + 1}`,
        filters.searchKeyword && `Search=${filters.searchKeyword}`,
        filters.status && `Status=${filters.status}`,
        pagination.sortField && `SortField=${pagination.sortField}`,
        pagination.sortOrder && `SortDirection=${pagination.sortOrder}`,
      ]
        .filter((param) => param)
        .join("&");

      const response: any = await api.get(
        `/v1/FinanceApplication?${queryParams}`
      );
      if (response && response.result) {
        dispatch(setApplicationList(response.result.items));
        dispatch(
          setPagination({
            pageIndex: response.result.page - 1,
            totalCount: response.result.totalCount,
            pageSize: response.result.pageSize,
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setApplicationListLoading(false));
    }
  };

export const getApplicationDetails = (id) => async (dispatch) => {
  try {
    const response: any = await api.get(`/v1/FinanceApplication/${id}`);
    dispatch(setApplicationDetail(response.result));
    dispatch(setUserFinanceDetailsForFinanceForm(response.result));
  } catch (error) {
    if (error?.status === "error" && error?.type === "NotFoundException") {
      // Redirect to "my applications" if the application is not found
      history.push("/user/my-application");
    }
    console.error("failed to fetch application details", error);
    dispatch(showMessage({ message: error.message, variant: "error" }));
  }
};

export const getApplicationStatus = (id) => async (dispatch) => {
  try {
    const response: any = await api.get(
      `/v1/FinanceApplicationProgress/GetFinanceApplicationStatusHistory?FinanceApplicationId=${id}`
    );
    if (response && response.result) {
      dispatch(setApplicationStatus(response.result));
    }
  } catch (error) {
    console.error("failed to fetch application details", error);
  }
};

export const updateFinanceApplicationSectionRemark =
  (request_data) => async (dispatch) => {
    try {
      const response: any = await api.put(
        `/v1/FinanceApplicationSectionRemark/UpdateFinanceApplicationSectionRemark`,
        request_data
      );
      dispatch(updateRemarkState(request_data));
    } catch (error) {
      console.error(
        "Failed to update finance application section remark",
        error
      );
    }
  };

export const getMemberList =
  ({ pageIndex }) =>
  async (dispatch, getState) => {
    try {
      const pagination = getPaginationData(getState());
      dispatch(setMemberListLoading(true));
      const response: any = await api.get(
        `/v1/User?PageSize=${pagination.pageSize}&PageIndex=${
          pageIndex + 1
        }&roleId=${roleId}`
      );
      if (response && response.result) {
        dispatch(setMemberList(response.result.items));
        dispatch(
          setPagination({
            pageIndex: response.result.page - 1,
            totalCount: response.result.totalCount,
            pageSize: response.result.pageSize,
          })
        );
      }
      dispatch(setMemberListLoading(false));
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setMemberListLoading(false));
      console.error(error);
    }
  };

export const getMemberListForFinance = () => async (dispatch, getState) => {
  const searchQuery = getMemberSearchKeyword(getState());
  try {
    const pagination = getMemberListPaginationData(getState());
    const queryParams = [
      pagination.pageSize && `PageSize=${pagination.pageSize}`,
      pagination.pageIndex && `PageIndex=${pagination.pageIndex + 1}`,
      searchQuery && `Search=${searchQuery}`,
    ]
      .filter((param) => param)
      .join("&");

    dispatch(setMemberListLoading(true));
    const response: any = await api.get(
      `/v1/User?roleId=${roleId}&${queryParams}`
    );
    if (response && response.result) {
      dispatch(setMemberList(response.result.items));
      return response.result.items;
    }
    return [];
  } catch (error) {
    dispatch(showMessage({ message: error.message, variant: "error" }));
    dispatch(setMemberListLoading(false));
    console.error(error);
    return [];
  } finally {
    dispatch(setMemberListLoading(false));
  }
};

export const getFinanceApplicationCommunication =
  (id) => async (dispatch, getState) => {
    const pagination = getChatListPaginationData(getState()); // get current state of pagination
    const pageSize = pagination?.pageSize;
    const pageIndex = pagination?.pageIndex + 1;
    dispatch(setIsChatListLoading(true)); // set chat to loading = true when api is called

    const queryParams = [
      pageSize && `PageSize=${pageSize}`, // pass pageSize
      pageIndex && `PageIndex=${pageIndex}`, // pass page index
      id && `FinanceApplicationId=${id}`, // pass the finance applicaiton id
    ]
      .filter((param) => param)
      .join("&"); // to remove falsy values (like undefined, null, and empty strings)

    try {
      const response: any = await api.get(
        `/v1/FinanceApplicationCommunication?${queryParams}`
      ); // pass all the payload data in query params
      if (pageIndex === 1) {
        dispatch(resetCommunicationChatList());
      }
      dispatch(setApplicationCommunication(response.result.items));
      dispatch(setIsChatListLoading(false));
      dispatch(
        setChatListPagination({
          pageIndex: response.result.page - 1,
          totalCount: response.result.totalCount,
          pageSize: response.result.pageSize,
        })
      );
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setIsChatListLoading(false));
      console.error(error);
    }
  };

export const sendFinanceApplicationCommunication =
  (request_data) => async (dispatch, getState) => {
    dispatch(setIsSendMessageLoading(true));
    try {
      const response: any = await api.post(
        "/v1/FinanceApplicationCommunication",
        request_data
      );
      if (response && response.result) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      console.error(error);
      return false;
    } finally {
      dispatch(setIsSendMessageLoading(false));
    }
  };

export const getMiniAgentList =
  ({ pageIndex }) =>
  async (dispatch, getState) => {
    try {
      const pagination = getPaginationData(getState());
      dispatch(setMiniAgentListLoading(true));
      const response: any = await api.get(
        `/v1/User?PageSize=${pagination.pageSize}&PageIndex=${
          pageIndex + 1
        }&roleId=${miniAgentRoleId}`
      );
      if (response && response.result) {
        dispatch(setMiniAgentList(response.result.items));
        dispatch(
          setPagination({
            pageIndex: response.result.page - 1,
            totalCount: response.result.totalCount,
            pageSize: response.result.pageSize,
          })
        );
      }
      dispatch(setMiniAgentListLoading(false));
    } catch (error) {
      dispatch(showMessage({ message: error.message, variant: "error" }));
      dispatch(setMiniAgentListLoading(false));
      console.error(error);
    }
  };

export const getApplicationDetailsForMobile =
  (id, Authorization) => async (dispatch) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${Authorization}`,
        },
      };
      const response: any = await api.get(
        `/v1/FinanceApplication/${id}`,
        config
      );
      dispatch(setApplicationDetail(response.result));
      dispatch(setUserFinanceDetailsForFinanceForm(response.result));
    } catch (error) {
      console.error("failed to fetch application details", error);
      dispatch(showMessage({ message: error.message, variant: "error" }));
    }
  };

/**
 * The initial state of the user slice.
 */
const initialState: UserType = {
  currentStepForMemberAdd: 0,
  applicationList: [],
  userData: {},
  role: [], // guest
  data: {
    id: "",
    displayName: "",
    photoURL: "",
    email: "",
    // shortcuts: ['apps.calendar', 'apps.mailbox', 'apps.contacts', 'apps.tasks']
  },
  memberList: [],
  searchMember: "",
  miniAgentList: [],
  pagination: {
    pageIndex: 0,
    pageSize: 10,
    totalCount: 0,
    sortField: "",
    sortOrder: "",
  },
  memberListPagination: {
    pageIndex: 0,
    pageSize: 20,
    totalCount: 0,
  },
  filters: {
    searchKeyword: "",
  },
  loading: {
    isButtonLoading: false,
    isImageLoading: false,
    isMemberListLoading: false,
    isApplicationListLoading: false,
    isSendMessageLoading: false,
    isAddMemberDialogOpen: false,
    isChatListLoading: false,
  },
  documentTypeList: [],
  selectedDocumentPath: null,
  documentList: [],
  userAdditionalInfo: {},
  userAddress: {},
  isEditAddressDialogOpen: false,
  isEditAdditionalInfoDialogOpen: false,
  isEditPersonalDetailDialogOpen: false,
  applicationDetails: {},
  applicationStatus: [],
  memberUserId: null,
  memberData: {},
  applicationCommunication: [],
  featurePermission: [],
  chatListPagination: {
    pageIndex: 0,
    pageSize: 20,
    totalCount: 0,
  },
};

export const getApplicationCommunicationChat = (state) =>
  state.user.applicationCommunication;
export const getLoginUserData = (state) => state.user.data.extraData;
export const getMemberDetail = (state) => state.user.memberData;
export const getMemberUserId = (state) => state.user.memberUserId;
export const getMiniAgentListData = (state) => state.user.miniAgentList;
export const getMemberListData = (state) => state.user.memberList;
export const getPaginationData = (state) => state.user.pagination;
export const getMemberListPaginationData = (state) =>
  state.user.memberListPagination;
export const getFilters = (state) => state.user.filters;
export const getSelectedDocumentPath = (state) =>
  state.user.selectedDocumentPath;
export const getApplicationListData = (state) => state.user.applicationList;
export const getApplicationStatusData = (state) => state.user.applicationStatus;
export const getApplicationDetailData = (state) =>
  state.user.applicationDetails;
export const getCurrentFinanceApplicationStatus = (state) =>
  state.user.applicationDetails?.financeApplicationStatus?.toLowerCase();
export const getIsFinanceApplicationApproved = (state) =>
  state.user.applicationDetails?.isApproved;
export const getDocuments = (state) => state.user.documentList;
export const getDocumentTypes = (state) => state.user.documentTypeList;
export const getUserAdditionalDetailData = (state) =>
  state.user.userAdditionalInfo;
export const getUserAddressData = (state) => state.user.userAddress;
export const getIsEditAddressDialogOpen = (state) =>
  state.user.isEditAddressDialogOpen;
export const getIsEditAdditionalInfoDialogOpen = (state) =>
  state.user.isEditAdditionalInfoDialogOpen;
export const getIsEditPersonalDetailDialogOpen = (state) =>
  state.user.isEditPersonalDetailDialogOpen;
export const getUserId = (state) => state.user.data.id;
export const getSlug = (state) => state.user.slug;
export const getUserData = (state) => state.user.userData;
export const getMemberSearchKeyword = (state) => state.user.searchMember;
export const getAccessTokenFromStore = (state) => state.user.accessToken;
export const getCurrentStepForMemberAdd = (state) =>
  state.user.currentStepForMemberAdd;
export const getChatListPaginationData = (state) =>
  state.user.chatListPagination;
const setLoadingState = (key, value) => async (dispatch) => {
  dispatch(setLoading({ key, value }));
};

export const setIsButtonLoading = (value) =>
  setLoadingState("isButtonLoading", value);
export const setIsImageLoading = (value) =>
  setLoadingState("isImageLoading", value);
export const setMemberListLoading = (value) =>
  setLoadingState("isMemberListLoading", value);
export const setApplicationListLoading = (value) =>
  setLoadingState("isApplicationListLoading", value);
export const setMiniAgentListLoading = (value) =>
  setLoadingState("isMiniAgentListLoading", value);
export const setIsSendMessageLoading = (value) =>
  setLoadingState("isSendMessageLoading", value);
export const setIsAddMemberDialogOpen = (value) =>
  setLoadingState("isAddMemberDialogOpen", value);
export const setIsChatListLoading = (value) =>
  setLoadingState("isChatListLoading", value);

export const getLoadingStateFactory = (loader) => (state) => {
  return state.user.loading[loader] || false;
};

export const selectIsButtonLoading = getLoadingStateFactory("isButtonLoading");
export const selectIsImageLoading = getLoadingStateFactory("isImageLoading");
export const getMemberListLoading = getLoadingStateFactory(
  "isMemberListLoading"
);
export const getApplicationListLoading = getLoadingStateFactory(
  "isApplicationListLoading"
);
export const getMiniAgentListLoading = getLoadingStateFactory(
  "isMiniAgentListLoading"
);
export const getIsSendMessageLoading = getLoadingStateFactory(
  "isSendMessageLoading"
);
export const getIsAddMemberDialogOpen = getLoadingStateFactory(
  "isAddMemberDialogOpen"
);
export const getIsChatListLoading = getLoadingStateFactory("isChatListLoading");

/**
 * The User slice
 */
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentStepForMember: (state, action: PayloadAction<number>) => {
      state.currentStepForMemberAdd = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.data.id = action.payload;
    },
    setMemberSearchKeyword: (state, action: PayloadAction<string>) => {
      state.searchMember = action.payload;
    },
    setMemberUserId: (state, action: PayloadAction<string>) => {
      state.memberUserId = action.payload;
    },
    setUserData: (state, action: PayloadAction<string>) => {
      state.userData = action.payload;
    },
    setMemberData: (state, action: PayloadAction<string>) => {
      state.memberData = action.payload;
    },
    setApplicationList: (state, action: PayloadAction<application[]>) => {
      state.applicationList = action.payload;
    },
    setPagination: (state, action: PayloadAction<any>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setMemberListPagination: (state, action: PayloadAction<any>) => {
      state.memberListPagination = {
        ...state.memberListPagination,
        ...action.payload,
      };
    },
    setFilters: (state, action: PayloadAction<Record<string, any>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setMemberList: (state, action: PayloadAction<Member[]>) => {
      state.memberList = action.payload;
    },
    setMiniAgentList: (state, action: PayloadAction<MiniAgent[]>) => {
      state.miniAgentList = action.payload;
    },
    setApplicationDetail: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.applicationDetails = action.payload;
    },
    updateRemarkState: (state, action) => {
      const { id, hasActionTakenByCustomer } = action.payload;
      const remark =
        state.applicationDetails.financeApplicationSectionRemark.find(
          (remark) => remark.id === id
        );
      if (remark) {
        remark.hasActionTakenByCustomer = hasActionTakenByCustomer;
      }
    },
    setApplicationStatus: (state, action: PayloadAction<Status[]>) => {
      state.applicationStatus = action.payload;
    },
    setIsEditPersonalDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditPersonalDetailDialogOpen = action.payload;
    },
    setIsEditAdditionalInfoDialogOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isEditAdditionalInfoDialogOpen = action.payload;
    },
    setUserAdditionalInfo: (state, action: PayloadAction<[]>) => {
      state.userAdditionalInfo = action.payload;
    },
    setUserAddress: (state, action: PayloadAction<[]>) => {
      state.userAddress = action.payload;
    },
    setDocumentTypeList: (state, action: PayloadAction<[]>) => {
      state.documentTypeList = action.payload;
    },
    setDocumentsList: (state, action: PayloadAction<[]>) => {
      state.documentList = action.payload;
    },
    setApplicationCommunication: (state, action: PayloadAction<[]>) => {
      state.applicationCommunication = [
        ...action.payload,
        ...state.applicationCommunication,
      ];
    },
    addNewMessage: (state, action: PayloadAction<any>) => {
      state.applicationCommunication.push(action.payload);
    },
    setSelectedDocumentPath: (state, action: PayloadAction<[]>) => {
      state.selectedDocumentPath = action.payload;
    },
    resetSelectedDocumentPath: (state) => {
      state.selectedDocumentPath = null;
    },
    setIsEditAddressDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditAddressDialogOpen = action.payload;
    },
    setIsEditDocumentDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditAddressDialogOpen = action.payload;
    },
    setChatListPagination: (state, action: PayloadAction<any>) => {
      state.chatListPagination = {
        ...state.chatListPagination,
        ...action.payload,
      };
    },
    // Reset communication chat list
    resetCommunicationChatList: (state) => {
      state.applicationCommunication = initialState.applicationCommunication;
    },
    setLoading: (state, action) => {
      const { key, value } = action.payload;
      state.loading = {
        ...state.loading,
        [key]: value,
      };
    },
    userLoggedOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setUser.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(
        setDisplayData,
        (state, action: PayloadAction<Partial<UserType["data"]>>) => {
          state.data = { ...state.data, ...action.payload };
        }
      );
    //   .addCase(updateUserShortcuts.fulfilled, (state, action) => {
    //     state.data.shortcuts = action.payload.data.shortcuts;
    //   })
    //   .addCase(updateUserSettings.fulfilled, (state, action) => {
    //     state.data.settings = action.payload.data.settings;
    //   })
  },
});

export const {
  setCurrentStepForMember,
  setMemberListPagination,
  userLoggedOut,
  setUserData,
  setMemberSearchKeyword,
  setMemberData,
  setApplicationList,
  setIsEditPersonalDialogOpen,
  setIsEditAdditionalInfoDialogOpen,
  setUserAddress,
  setUserAdditionalInfo,
  setIsEditAddressDialogOpen,
  resetSelectedDocumentPath,
  setDocumentTypeList,
  setSelectedDocumentPath,
  setDocumentsList,
  setApplicationDetail,
  setApplicationStatus,
  setIsEditDocumentDialogOpen,
  setPagination,
  setMemberList,
  setMiniAgentList,
  setMemberUserId,
  setLoading,
  setApplicationCommunication,
  updateRemarkState,
  setFilters,
  addNewMessage,
  setChatListPagination,
  resetCommunicationChatList,
} = userSlice.actions;

export const selectUser = (state: AppRootStateType) => state.user;

export const selectFeaturePermission = (
  state: AppRootStateType
): Permission[] => state.user.featurePermission;

export const selectUserRole = (state: AppRootStateType) => state.user.role;

export const selectIsUserGuest = (state: AppRootStateType) => {
  const { role } = state.user;

  return !role || role.length === 0;
};

export const selectUserShortcuts = (state: AppRootStateType) =>
  state.user.data.shortcuts;

export type userSliceType = typeof userSlice;

export default userSlice.reducer;
