import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import CustomTextFieldWithIcon from "app/shared-components/Form/CustomTextFieldWithIcon";
import { styled } from "@mui/system";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { useTranslation } from "react-i18next";
import ColoredSubmitButton from "../Button/ColoredSubmitButton";

i18next.addResourceBundle("en", "OtpVerification", en);
i18next.addResourceBundle("ms", "OtpVerification", ms);

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.secondary.main,
  paddingRight: "96px",
  paddingLeft: "96px",
  marginTop: "20px",
}));

const OtpVerification = ({
  onOtpSubmit,
  onResendOtp,
  color,
  loading,
  isLogin,
  phoneNumber,
}) => {
  const { t } = useTranslation("OtpVerification");
  const theme = useTheme();
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);

  const schema = yup.object().shape({
    otp: yup
      .string()
      .matches(/^\d+$/, t("OTP_SHOULD_BE_NUMERIC"))
      .length(6, t("OTP_ERROR_MESSAGE"))
      .required(t("OTP_ERROR_MESSAGE")),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      otp: "",
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = methods;

  useEffect(() => {
    let timeout;
    if (timer > 0) {
      timeout = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearTimeout(timeout);
  }, [timer]);

  const handleOtpSubmit = (data) => {
    onOtpSubmit({
      otp: data.otp,
    });
  };

  const handleResendOtp = () => {
    setCanResend(false);
    setTimer(30);
    onResendOtp();
  };

  const maskPhoneNumber = (phoneNumber) => {
    const lastThree = phoneNumber?.slice(-3);
    const maskedSection = "*".repeat(phoneNumber?.length - 3);
    return `${maskedSection}${lastThree}`;
  };

  return (
    <Box className="flex flex-col gap-10 mt-10">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(handleOtpSubmit)}
          className="flex flex-col gap-10"
        >
          <Box className="flex flex-col justify-between gap-10">
            <div>
              <Typography
                className="my-8"
                style={{
                  color: isLogin
                    ? theme.palette.common.white
                    : theme.palette.common.black,
                }}
              >
                {t("OTP_SEND_MESSAGE")} &nbsp;
                {maskPhoneNumber(phoneNumber)}
              </Typography>
              <Controller
                name="otp"
                control={control}
                render={({ field }) => (
                  <CustomTextFieldWithIcon
                    color={color}
                    id="otp"
                    label={t("ENTER_OTP")}
                    autoComplete="off"
                    value={field.value}
                    autoFocus
                    onChange={field.onChange}
                    inputProps={{
                      maxLength: 6,
                      pattern: "[0-9]*",
                      'data-test-id': "otp-input",
                    }}
                    fullWidth
                    error={errors.otp}
                  />
                )}
              />
              <Box className="flex justify-end items-center">
                <Button
                  variant="text"
                  className=" capitalize"
                  onClick={handleResendOtp}
                  disabled={!canResend}
                  style={{
                    color: isLogin
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                  }}
                >
                  {canResend
                    ? t("RESEND_OTP")
                    : `${t("RESEND_IN")} ${timer}'sec`}
                </Button>
              </Box>
            </div>
          </Box>
          <Box
            className={`flex ${isLogin ? "justify-center" : "justify-end"
              } items-center mt-5`}
          >
            {isLogin ? (
              <StyledButton
                theme={theme}
                variant="contained"
                className="capitalize"
                type="submit"
                data-test-id="submit-otp-btn"
              >
                {!loading ? (
                  t("VERIFY_BUTTON")
                ) : (
                  <CircularProgress color="secondary" size={24} />
                )}
              </StyledButton>
            ) : (
              <ColoredSubmitButton
                isLoading={loading}
                text={t("VERIFY_BUTTON")}
                className="px-20"
                data-test-id="submit-otp-btn"
              />
            )}
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

OtpVerification.propTypes = {
  onOtpSubmit: PropTypes.func.isRequired,
  onResendOtp: PropTypes.func.isRequired,
  isLogin: PropTypes.bool,
};

OtpVerification.defaultProps = {
  isLogin: true,
};

export default OtpVerification;
