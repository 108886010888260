import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Box } from "@mui/material";
import {
  fileUpload,
  getSelectedDocumentPath,
  selectIsImageLoading,
  setSelectedDocumentPath,
} from "app/store/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import ColoredSubmitButton from "../Button/ColoredSubmitButton";
import { StyledTypographyGrey } from "../Styles/CustomStyle";
import i18next from "i18next";
import en from "./i18n/en";
import ms from "./i18n/ms";
import { useTranslation } from "react-i18next";

i18next.addResourceBundle("en", "signature", en);
i18next.addResourceBundle("ms", "signature", ms);

const SignatureComponent = ({ onSave }) => {
  const { t } = useTranslation("signature");
  const sigCanvas = useRef(null);
  const dispatch: any = useDispatch();
  const filePath = useSelector(getSelectedDocumentPath);
  const isImageLoading = useSelector(selectIsImageLoading);
  const [isEmpty, setIsEmpty] = useState(true);

  const clearSignature = () => {
    sigCanvas.current.clear();
    setIsEmpty(true);
  };

  const handleSignatureStart = () => {
    setIsEmpty(false);
  };

  useEffect(() => {
    return () => {
      // Cleanup function
      dispatch(setSelectedDocumentPath());
    };
  }, [dispatch]);

  useEffect(() => {
    if (filePath) {
      onSave(filePath);
    }
  }, [filePath, onSave]);

  const saveSignature = async () => {
    const signatureData = sigCanvas.current.toDataURL("image/png");

    if (signatureData) {
      const response = await fetch(signatureData);
      const blob = await response.blob();

      const file = new File([blob], "captured-image.png", {
        type: "image/png",
      });

      if (file) {
        dispatch(fileUpload(file));
      }
    }
  };

  return (
    <Box className="flex flex-col gap-10">
      <Box className="flex justify-center items-center border-dashed border-2 border-black p-4 w-full h-full relative">
        <SignatureCanvas
          ref={sigCanvas}
          penColor="black"
          onBegin={handleSignatureStart}
          canvasProps={{
            className: "cursor-crosshair signature-canvas",
            "data-test-id": "signature-canvas",
            style: {
              height: "200px",
              width: "100%",
              maxWidth: "540px",
            },
          }}
        />
        {isEmpty && (
          <StyledTypographyGrey className="font-normal top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2 pointer-events-none">
            {t("SIGN_HERE")}
          </StyledTypographyGrey>
        )}
      </Box>
      <Box className="flex justify-end gap-10">
        <ColoredSubmitButton
          variant="outlined"
          onClick={clearSignature}
          text={t("CLEAR")}
          data-test-id="signature-clear-btn"
        />
        <ColoredSubmitButton
          variant="contained"
          onClick={saveSignature}
          isLoading={isImageLoading}
          disabled={isEmpty}
          text={t("SAVE")}
          data-test-id="signature-save-btn"
        />
      </Box>
    </Box>
  );
};

export default SignatureComponent;
