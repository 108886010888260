import React, { useEffect, useState } from "react";
import { StyledTypography } from "app/shared-components/Styles/CustomStyle";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Chip,
  Slider,
  Alert,
} from "@mui/material";
import CustomTextField from "app/shared-components/Form/CustomTextField";
import CustomAutocomplete from "app/shared-components/Form/CustomAutoComplete";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import themesConfig from "app/configs/themesConfig";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  ApplyForFinance,
  getActiveFinanceResponse,
  getCategory,
  getCategoryList,
  getFinanceProductList,
  getFinanceProductListData,
  getIsActiveFinanceRedirectDialogOpen,
  getProduct,
  getProductList,
  getUserFinanceApplicationForFinanceForm,
  getUserFinanceDetailsDataInFinanceForm,
  selectIsButtonLoading,
  setIsActiveFinanceRedirectDialogOpen,
  updateUserFinanceDetailsInForm,
} from "app/store/application-form/applicationFormSlice";
import { tenantId, durationRange, loginUserRoles } from "src/app/common/constant";
import ColoredSubmitButton from "app/shared-components/Button/ColoredSubmitButton";
import { formatAmount, hasPermission } from "src/app/services/utils";
import { isEmpty } from "lodash";
import {
  getIsAddMemberDialogOpen,
  getLoginUserData,
  getMemberListForFinance,
  getSlug,
  getUserId,
  setIsAddMemberDialogOpen,
  setMemberSearchKeyword,
} from "app/store/user/userSlice";
import MemberAutoComplete from "app/shared-components/MemberAutoComplete/MemberAutoComplete";
import PreviewMedia from "app/shared-components/Dialog/PreviewMedia";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InfoDialog from "app/shared-components/Dialog/InfoDialog";
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import history from "@history";
import { setMemberListLoading } from "app/store/user/userSlice";
import CustomDialog from "app/shared-components/Dialog/customDialog";
import AddMemberStepper from "../../user/component/member/AddMemberStepper";
import { FeaturePermissions } from "src/app/common/FeaturePermissions";

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  padding: "20px 20px",
}));

interface FormModel {
  member: string;
  categoryId: string;
  productId: string;
  productVariantId: string;
  financeAmount: number;
  durationMonth: number;
  loanRateType: string;
  financeProductId: string;
  hasConsumerProductAllow: boolean;
  agentCode: string;
}

export default function ProductDetails({
  financeId = null,
  isEdit = false,
  setIsDirty = (value) => {},
}) {
  const { t } = useTranslation("applicationForm");
  const dispatch: any = useDispatch();
  const [search] = useSearchParams();
  const categoryList = useSelector(getCategoryList);
  const productList = useSelector(getProductList);
  const slug = useSelector(getSlug);
  const memberId = search.get("userId");
  const financeDetails = useSelector(getUserFinanceDetailsDataInFinanceForm);
  const isAddMemberDialogOpen = useSelector(getIsAddMemberDialogOpen);
  const loginData = useSelector(getLoginUserData);
  const financeApplicationId = search.get("id") || null;
  const categoryId = search.get("categoryId") || null;
  const productId = search.get("productId") || null;
  const productVariantId = search.get("productVariantId") || null;
  const [productVariantOption, setProductVariantOption] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [selectedProductVariant, setSelectedProductVariant] = useState<any>({});
  const [selectedMember, setSelectedMember] = useState(null);
  const userId = useSelector(getUserId);
  const isLoading: boolean = useSelector(selectIsButtonLoading);
  const productOptions = productList.map((product) => ({
    label: product.name,
    id: product.id,
  }));
  const isActiveFinanceRedirectDialogOpen = useSelector(
    getIsActiveFinanceRedirectDialogOpen
  );
  const activeFinanceResponse = useSelector(getActiveFinanceResponse);
  const financeProductList = useSelector(getFinanceProductListData);
  const modifiedFinanceProductList = financeProductList.map((item) => ({
    label: item.name,
    id: item.id,
  }));
  const [selectedFinanceProduct, setSelectedFinanceProduct] = useState(null);
  const [selectedFinanceProductId, setSelectedFinanceProductId] = useState("");
  const [showConsumerProductAllow, setShowConsumerProductAllow] =
    useState(false);
  const [isConsumerProductAllow, setIsConsumerProductAllow] = useState(false);
  const [minTenure, setMinTenure] = useState(durationRange.minDuration); // Default min
  const [maxTenure, setMaxTenure] = useState(durationRange.maxDuration); // Default max
  const [alertMessage, setAlertMessage] = useState("");

  const userListPermission = hasPermission(FeaturePermissions.User.List);

  const schema = yup.object().shape({
    member:
      isEdit || slug === loginUserRoles.Customer
        ? yup.string().notRequired()
        : yup.string().required(t("SELECT_FIELD_REQUIRED")),
    categoryId: yup
      .string()
      .test(
        "categoryId-validation",
        t("SELECT_FIELD_REQUIRED"),
        function (value) {
          // Access hasConsumerProductAllow from the context
          const { hasConsumerProductAllow } = this.parent;
          // Conditionally validate categoryId based on hasConsumerProductAllow
          if (hasConsumerProductAllow) {
            return !!value && value.trim() !== ""; // Require categoryId if hasConsumerProductAllow is true
          }
          return true; // No validation if hasConsumerProductAllow is false
        }
      ),
    productId: yup
      .string()
      .test("product-validation", t("SELECT_FIELD_REQUIRED"), function (value) {
        // Access hasConsumerProductAllow from the context
        const { categoryId } = this.parent;

        // Conditionally validate product id based on based on category
        if (categoryId && productOptions.length > 0) {
          return !!value && value.trim() !== ""; // Require product if category get and productOption get
        }
        return true; // No validation if category and productOption not get
      }),
    financeProductId: yup.string().required(t("SELECT_FIELD_REQUIRED")),
    financeAmount: yup
      .number()
      .required(
        t("TEXT_FIELD_REQUIRED_VALIDATION", { fieldName: t("FINANCE_AMOUNT") })
      )
      .typeError(t("NUMBER_INVALID_VALIDATION")),
    durationMonth: yup
      .number()
      .required("TENURE_OF_APPLICATION_VALIDATION")
      .positive("TENURE_OF_APPLICATION_POSITIVE")
      .typeError("TENURE_OF_APPLICATION_INVALID"),
    agentCode: yup
      .string()
      .nullable() // Allow null or empty value without triggering validation
      .matches(/^\d+$/, {
        message: t("NUMBER_VALIDATION_FOR_AGENT_CODE"),
        excludeEmptyString: true, // Prevents firing on empty values
      })
      .test(
        "len",
        t("REFERRAL_CODE_LENGTH"),
        (val) => !val || val.length === 6 // Only checks length if a value is present
      ),
  });

  const methods = useForm<FormModel>({
    defaultValues: {
      durationMonth: minTenure,
      hasConsumerProductAllow: false,
    },
    mode: "onChange",
    resolver: yupResolver<any>(schema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    getValues,
    setValue,
    watch,
    trigger,
  } = methods;

  const formValues = getValues();

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getFinanceProductList());

    return () => {
      dispatch(setMemberSearchKeyword(""));
      reset();
    };
  }, [slug, dispatch]);

  useEffect(() => {
    setIsDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (financeApplicationId || financeId) {
      if (!Object.keys(financeDetails).length) {
        const idToFetch = financeApplicationId || financeId;
        dispatch(getUserFinanceApplicationForFinanceForm(idToFetch));
      }
    }
  }, []);

  useEffect(() => {
    if (slug === loginUserRoles.Customer) {
      setSelectedMember(userId);
    }
  }, [dispatch, userId, selectedMember]);

  useEffect(() => {
    if (memberId) {
      setSelectedMember(memberId);
    }
  }, [memberId]);

  useEffect(() => {
    setValue("member", selectedMember);
  }, [selectedMember, setValue]);

  useEffect(() => {
    const selectedProduct = financeProductList.find(
      (product) => product.id === selectedFinanceProductId
    );

    setShowConsumerProductAllow(!!selectedProduct?.hasConsumerProductAllow);
  }, [selectedFinanceProductId, financeProductList]);

  useEffect(() => {
    if (!financeDetails || !financeProductList) return;

    // Set initial form values from financeDetails
    setValue("durationMonth", financeDetails.durationMonth);
    setValue("financeAmount", financeDetails.financeAmount);
    setValue("financeProductId", financeDetails.financeProductId || "");

    // IF Agent Code Get Then Prefilled
    if (!!financeDetails?.agentUser?.userUniqueCode) {
      setValue("agentCode", financeDetails?.agentUser?.userUniqueCode);
    } else if (["agent", "mini-agent"].includes(slug)) {
      setValue("agentCode", loginData?.userUniqueCode);
    } else {
      setValue("agentCode", "");
    }

    // Find the matching finance product
    const selectedProduct = financeProductList.find(
      (product) => product.id === financeDetails.financeProductId
    );

    // Set selected product and its related states
    setSelectedFinanceProduct(selectedProduct);

    if (selectedProduct) {
      setMinTenure(selectedProduct.minTenure);
      setMaxTenure(selectedProduct.maxTenure);

      const hasConsumerProductAllow = selectedProduct.hasConsumerProductAllow;
      setShowConsumerProductAllow(hasConsumerProductAllow);

      // Set additional fields if consumer product is allowed
      if (financeDetails.categoryId) {
        setValue("hasConsumerProductAllow", hasConsumerProductAllow);
        setValue("categoryId", financeDetails.categoryId);
        dispatch(getProduct(financeDetails.categoryId));
        setValue("productId", financeDetails.productId);
        setValue("productVariantId", financeDetails.productVariantId);
      } else {
        setValue("hasConsumerProductAllow", false);
        setValue("categoryId", "");
        setValue("productId", "");
        setValue("productVariantId", "");
      }
    }

    // Reset form with updated values
    reset(getValues());
  }, [financeDetails, financeProductList, setValue, reset, getValues]);

  useEffect(() => {
    const shouldFetchProduct =
      (financeDetails?.categoryId &&
        financeDetails.categoryId !== formValues.categoryId) ||
      (formValues.categoryId &&
        formValues.categoryId !== financeDetails?.categoryId);

    if (shouldFetchProduct) {
      dispatch(getProduct(formValues.categoryId || financeDetails?.categoryId));
    }
  }, [formValues.categoryId, financeDetails?.categoryId, dispatch]);

  useEffect(() => {
    if (formValues.productId) {
      const selectedProductDetails = productList.find(
        (product) => product.id === formValues.productId
      );
      setSelectedProduct(selectedProductDetails);

      if (selectedProductDetails) {
        const variantOptions = selectedProductDetails.productVariantList.map(
          (variant) => {
            const parts = [];

            if (variant.productVariantName)
              parts.push(variant.productVariantName);
            if (variant.color) parts.push(variant.color);
            if (variant.storage) parts.push(variant.storage);

            return {
              label: parts.join(" - "),
              id: variant.id,
            };
          }
        );

        setProductVariantOption([...variantOptions]);
        if (formValues.productVariantId) {
          const selectedProductVariantDetails =
            selectedProductDetails.productVariantList.find(
              (variant) => variant.id === formValues.productVariantId
            );
          setSelectedProductVariant(selectedProductVariantDetails);

          if (
            selectedProductVariantDetails &&
            selectedProductVariantDetails.price &&
            !formValues.financeAmount
          ) {
            setValue("financeAmount", selectedProductVariantDetails.price, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }
        } else if (formValues.productId) {
          setValue("financeAmount", selectedProductDetails.price, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }
    }
  }, [
    formValues.productId,
    formValues.productVariantId,
    productList,
    dispatch,
  ]);

  useEffect(() => {
    if (showConsumerProductAllow && isConsumerProductAllow) {
      setValue("categoryId", financeDetails.categoryId || categoryId || "");
      dispatch(getProduct(categoryId));
      setValue("productId", financeDetails.productId || productId || "");
      setValue(
        "productVariantId",
        financeDetails.productVariantId || productVariantId || ""
      );
    }
  }, [showConsumerProductAllow, isConsumerProductAllow]);

  const validationFunctions = (financeAmount) => {
    let errors = {
      financeAmount: "",
    };
    const priceToCheck = selectedProductVariant.price || selectedProduct.price; // Default to product price if no variant selected
    if (financeAmount < priceToCheck) {
      errors.financeAmount = t("MINIMUM_VALIDATION", {
        min: priceToCheck,
        fieldName: t("FINANCE_AMOUNT"),
      });
    }

    // Validate financeAmount
    if (financeAmount < selectedFinanceProduct.minAmount) {
      errors.financeAmount = t("MINIMUM_VALIDATION", {
        min: selectedFinanceProduct.minAmount,
        fieldName: t("FINANCE_AMOUNT"),
      });
    }

    if (financeAmount > selectedFinanceProduct.maxAmount) {
      errors.financeAmount = t("MAXIMUM_VALIDATION", {
        max: selectedFinanceProduct.maxAmount,
        fieldName: t("FINANCE_AMOUNT"),
      });
    }

    return errors;
  };

  useEffect(() => {
    const currentFinanceAmount = watch("financeAmount");

    if (currentFinanceAmount && alertMessage) {
      const errors = validationFunctions(currentFinanceAmount);

      // If there's a validation error, set it in the alertMessage state
      if (errors.financeAmount) {
        setAlertMessage(errors.financeAmount);
      } else {
        setAlertMessage(""); // Clear alert if validation passes
      }
    }
  }, [watch("financeAmount")]);

  function onSubmit(model, event) {
    event.preventDefault();
    setAlertMessage("");
    const errors = validationFunctions(model.financeAmount);
    if (Object.keys(errors).length > 0 && errors.financeAmount) {
      // Show the first error message in alert
      setAlertMessage(errors.financeAmount);
      return; // Prevent form submission
    }
    // Remove fields with empty string values
    Object.keys(model).forEach((key) => {
      if (model[key] === "") {
        delete model[key];
      }
    });
    if (
      Object.keys(financeDetails).length !== 0 &&
      financeDetails?.isDefault !== true
    ) {
      const update_data = {
        ...model,
        id: financeDetails?.id,
      };
      dispatch(
        updateUserFinanceDetailsInForm(update_data, financeDetails?.id, isEdit)
      );
    } else {
      const request_data = {
        ...model,
        userId: selectedMember,
        tenantId: tenantId,
      };
      dispatch(ApplyForFinance(request_data));
    }
  }

  const handleCategoryChange = () => {
    setValue("productId", "");
    setValue("productVariantId", "");
    setProductVariantOption([]);
    setSelectedProduct({});
    setSelectedProductVariant({});
    setValue("financeAmount", financeDetails?.financeAmount || "");
  };

  const handleProductChange = () => {
    setValue("productVariantId", "");
    setProductVariantOption([]);
    setSelectedProductVariant({});
    setValue("financeAmount", financeDetails?.financeAmount || "");
  };

  const handleProductVariantChange = (id) => {
    if (!id) {
      setSelectedProductVariant({});
      return;
    }
    const selectedProductDetails = productList.find(
      (product) => product.id === formValues.productId
    );
    if (selectedProductDetails) {
      const selectedProductVariantDetails =
        selectedProductDetails.productVariantList.find(
          (variant) => variant.id === id
        );
      setSelectedProductVariant(selectedProductVariantDetails);

      if (
        selectedProductVariantDetails &&
        selectedProductVariantDetails.price
      ) {
        setValue("financeAmount", selectedProductVariantDetails.price);
        reset(getValues());
      }
    }
  };

  const handleMemberChange = (value) => {
    setSelectedMember(value ? value.id : null);
  };

  const handleFinanceProductChange = (selectedOption, onChange) => {
    const id = selectedOption?.id || "";
    onChange(id); // Update the field with the id or reset it
    setIsConsumerProductAllow(false);
    if (id && financeProductList.length > 0) {
      const matchedFinanceProduct = financeProductList.find(
        (product) => product.id === id
      );
      setSelectedFinanceProduct(matchedFinanceProduct);
      setMinAndMaxTenure(matchedFinanceProduct);
    } else {
      setSelectedFinanceProduct(null);
      setMinAndMaxTenure(null);
    }
    setValue("categoryId", "");
    setValue("productId", "");
    setValue("productVariantId", "");
    setSelectedProduct({});
    setSelectedProductVariant({});
    setSelectedFinanceProductId(id);
    setValue("hasConsumerProductAllow", false);
  };

  const setMinAndMaxTenure = (financeProduct) => {
    if (financeProduct) {
      setMinTenure(financeProduct.minTenure);
      setMaxTenure(financeProduct.maxTenure);
      // setValue("durationMonth", financeProduct.minTenure);
    } else {
      setMinTenure(null);
      setMaxTenure(null);
    }
  };

  const resetCategoryProductVariant = () => {
    setValue("categoryId", "");
    setValue("productId", "");
    setValue("productVariantId", "");
    setProductVariantOption([]);
    setSelectedProduct({});
    setSelectedProductVariant({});
    setValue("financeAmount", null);
    setIsConsumerProductAllow(false);
  };

  const handleHasConsumerProduct = () => {
    setIsConsumerProductAllow(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
          noValidate
        >
          <StyledTypography variant="body1" className="mt-20">
            {t("PRODUCT_DETAILS")}
          </StyledTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mb-8">
                  <Autocomplete
                    options={[
                      { label: t("UNSECURED_LOAN"), id: "unsecured-loan" },
                    ]}
                    getOptionLabel={(option) => option.label}
                    value={{ label: t("UNSECURED_LOAN"), id: "unsecured-loan" }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        label={t("LOAN_TYPE")}
                        variant="filled"
                        fullWidth
                      />
                    )}
                    disabled
                  />
                </Grid>

                {!(slug === loginUserRoles.Customer) && !isEdit && !memberId && userListPermission && (
                  <Grid item xs={12} className="mb-8">
                    <Controller
                      name="member"
                      control={control}
                      render={({ field }) => (
                        <MemberAutoComplete
                          label={t("MEMBER_LIST")}
                          dataTestId="member-selection-field"
                          onChange={(value: any) => {
                            if (value && value.id) {
                              field.onChange(value.id);
                              handleMemberChange(value);
                            } else {
                              field.onChange(null);
                              handleMemberChange(null);
                            }
                            dispatch(setMemberListLoading(true));
                          }}
                          options={{
                            showAddOption: true,
                            onAddClick: () =>
                              dispatch(setIsAddMemberDialogOpen(true)),
                          }}
                          apiCallPermission={userListPermission}
                          value={selectedMember || null}
                          disabled={!!memberId || !!financeDetails?.userId}
                          error={errors.member && t(errors.member.message)}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} className="mb-8">
                  <Controller
                    name="financeProductId"
                    control={control}
                    render={({ field }) => (
                      <CustomAutocomplete
                        label={t("FINANCE_PRODUCT")}
                        data-test-id="finance-product-selection"
                        onChange={(value) =>
                          handleFinanceProductChange(value, field.onChange)
                        }
                        value={field.value || ""}
                        required
                        options={
                          modifiedFinanceProductList &&
                          modifiedFinanceProductList.length > 0 &&
                          modifiedFinanceProductList.map((item) => ({
                            label: item.label,
                            id: item.id,
                          }))
                        }
                        error={
                          errors.financeProductId &&
                          t(errors.financeProductId.message)
                        }
                      />
                    )}
                  />
                </Grid>

                {showConsumerProductAllow && (
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Controller
                            name="hasConsumerProductAllow"
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                data-test-id="has-consumer-allow-product"
                                checked={field.value || false}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  if (!e.target.checked) {
                                    resetCategoryProductVariant();
                                  } else {
                                    handleHasConsumerProduct();
                                  }
                                }}
                              />
                            )}
                          />
                        }
                        label={t("HAS_CONSUMER_PRODUCT_ALLOW")}
                      />
                    </FormGroup>
                  </Grid>
                )}

                {/* category selection */}
                {showConsumerProductAllow &&
                  watch("hasConsumerProductAllow") && (
                    <Grid item xs={12} className="mb-8">
                      <Controller
                        name="categoryId"
                        control={control}
                        render={({ field }) => (
                          <CustomAutocomplete
                            data-test-id="category-field"
                            label={t("CATEGORY")}
                            onChange={(value: any) => {
                              if (value && value.id) {
                                field.onChange(value.id); // Set the selected value in the form state
                              } else {
                                field.onChange("");
                              }
                              handleCategoryChange(); // Fetch products based on the selected category ID
                            }}
                            value={field.value || ""}
                            required
                            options={
                              categoryList &&
                              categoryList.length > 0 &&
                              categoryList.map((item) => ({
                                label: item.name,
                                id: item.id,
                              }))
                            }
                            error={
                              errors.categoryId && t(errors.categoryId.message)
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}

                {/* product selection */}
                {watch("hasConsumerProductAllow") === true &&
                  formValues.categoryId &&
                  productOptions.length > 0 && (
                    <Grid item xs={12} className="mb-8">
                      <Controller
                        name="productId"
                        control={control}
                        render={({ field }) => (
                          <CustomAutocomplete
                            label={t("SELECT_PRODUCT")}
                            onChange={(value: any) => {
                              if (value && value.id) {
                                field.onChange(value.id); // Set the selected value in the form state
                              } else {
                                field.onChange("");
                              }
                              handleProductChange(); // Fetch products based on the selected category ID
                            }}
                            value={field.value || ""}
                            options={productOptions}
                            required
                            error={
                              errors.productId && t(errors.productId.message)
                            }
                            data-test-id="product-field"
                          />
                        )}
                      />
                    </Grid>
                  )}

                {/* product variant selection */}
                {watch("hasConsumerProductAllow") === true &&
                  formValues.productId &&
                  productVariantOption.length > 0 && (
                    <Grid item xs={12} className="mb-8">
                      <Controller
                        name="productVariantId"
                        control={control}
                        render={({ field }) => (
                          <CustomAutocomplete
                            label={t("PRODUCT_VARIANT")}
                            onChange={(value: any) => {
                              if (value && value.id) {
                                field.onChange(value.id);
                                handleProductVariantChange(value.id); // Fetch products based on the selected category ID
                              } else {
                                field.onChange("");
                                handleProductVariantChange(null); // Fetch products based on the selected category ID
                              }
                            }}
                            value={field.value || ""}
                            options={productVariantOption}
                            error={
                              errors.productVariantId &&
                              t(errors.productVariantId.message)
                            }
                            data-test-id="add-application-product-variant"
                          />
                        )}
                      />
                    </Grid>
                  )}

                <Grid item xs={12} className="mb-8">
                  <Controller
                    name="financeAmount"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("FINANCE_AMOUNT")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        required
                        error={
                          errors.financeAmount &&
                          t(errors.financeAmount.message)
                        }
                        inputProps={{
                          "data-test-id": "finance-amount-input-field"
                        }}
                      />
                    )}
                  />
                </Grid>

                {/* Agent Code */}
                <Grid item xs={12} className="mb-8">
                  <Controller
                    name="agentCode"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label={t("AGENT_CODE")}
                        onChange={field.onChange}
                        value={field.value || ""}
                        fullWidth
                        disabled={!!financeDetails?.agentUser?.userUniqueCode}
                        inputProps={{ maxLength: 6 }}
                        error={errors.agentCode && t(errors.agentCode.message)}
                        data-test-id="agent-code-input-field"
                      />
                    )}
                  />
                </Grid>

                {/* duration month */}
                <Grid item xs={12} className="mb-8">
                  <Typography
                    variant="body1"
                    className="flex text-12 justify-between	"
                  >
                    <span>{t("APPLICATION_TENURE")} </span>
                    <span className="font-bold">
                      {watch("durationMonth")} Months
                    </span>
                  </Typography>
                  <Controller
                    name="durationMonth"
                    control={control}
                    render={({ field }) => (
                      <div className="pl-10 pr-16 mt-10">
                        <Slider
                          // data-testid="loan-duration-slider"
                          className="duration-slider"
                          data-cy={"loan-duration-slider"}
                          aria-label="Duration of Loan"
                          valueLabelDisplay="auto"
                          step={1}
                          min={minTenure || durationRange.minDuration} // Dynamic min value
                          max={maxTenure || durationRange.maxDuration} // Dynamic max value
                          marks={
                            minTenure && maxTenure
                              ? [
                                  {
                                    value: minTenure,
                                    label: `${minTenure}`,
                                  },
                                  {
                                    value: maxTenure,
                                    label: `${maxTenure}`,
                                  },
                                ]
                              : false
                          }
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </div>
                    )}
                  />
                  {errors.durationMonth && (
                    <span className="text-red-500 text-sm">
                      {t(errors.durationMonth.message)}
                    </span>
                  )}
                </Grid>
              </Grid>
              {alertMessage && <Alert severity="error">{alertMessage}</Alert>}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {!isEmpty(selectedProduct) && (
                <DemoPaper variant="outlined">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <PreviewMedia
                        filePath={selectedProduct.image}
                        showDefaultView={false}
                      >
                        <img
                          src={selectedProduct.image}
                          alt="product"
                          className="w-full h-full max-h-216 object-contain cursor-pointer"
                        />
                      </PreviewMedia>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Box className="flex flex-col">
                        <StyledTypography variant="h5">
                          {selectedProductVariant?.productName ||
                            selectedProduct?.name}
                        </StyledTypography>
                        <p
                          color={themesConfig.default.palette.primary.light}
                          dangerouslySetInnerHTML={{
                            __html: selectedProduct.shortDescription,
                          }}
                        ></p>
                        {selectedProductVariant &&
                          selectedProductVariant.color && (
                            <Typography variant="body1">
                              Colour : {selectedProductVariant?.color}
                            </Typography>
                          )}
                        {selectedProductVariant &&
                          selectedProductVariant?.storage && (
                            <Chip
                              label={selectedProductVariant?.storage}
                              variant="outlined"
                              className="w-96"
                            ></Chip>
                          )}
                        {selectedProductVariant?.price ? (
                          <Box className="flex items-center gap-20">
                            <StyledTypography variant="h5">
                              {formatAmount(selectedProductVariant?.price)}
                            </StyledTypography>
                            {selectedProductVariant?.originalPrice -
                              selectedProductVariant?.price >
                              0 && (
                              <Typography
                                variant="caption"
                                fontWeight="bold"
                                color={
                                  themesConfig.default.palette.primary.light
                                }
                              >
                                Save{" "}
                                {formatAmount(
                                  selectedProductVariant.originalPrice -
                                    selectedProductVariant.price
                                )}
                              </Typography>
                            )}
                          </Box>
                        ) : (
                          <Box className="flex items-center gap-20">
                            <StyledTypography variant="h5">
                              {formatAmount(selectedProduct.price)}
                            </StyledTypography>
                            {selectedProduct.originalPrice -
                              selectedProduct.price >
                              0 && (
                              <Typography
                                variant="caption"
                                fontWeight="bold"
                                color={
                                  themesConfig.default.palette.primary.light
                                }
                              >
                                Save{" "}
                                {formatAmount(
                                  selectedProduct.originalPrice -
                                    selectedProduct.price
                                )}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </DemoPaper>
              )}
            </Grid>
          </Grid>
          <Box>
            <Box className="flex justify-end items-end gap-20 mt-10">
              {isEdit ? (
                <ColoredSubmitButton
                  className="w-120"
                  // disabled={!isValid || !isDirty}
                  text={t("SAVE")}
                  isLoading={isLoading}
                  data-test-id="submit-application-btn"
                ></ColoredSubmitButton>
              ) : (
                <>
                  <ColoredSubmitButton
                    className="w-120"
                    // disabled={!isValid}
                    text={t("NEXT")}
                    isLoading={isLoading}
                    data-test-id="submit-application-btn"
                  ></ColoredSubmitButton>
                </>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>

      {/* if Active Finance Application And Make New Finance Then Open Dialog */}
      {isActiveFinanceRedirectDialogOpen && (
        <InfoDialog
          open={isActiveFinanceRedirectDialogOpen}
          onClose={() => dispatch(setIsActiveFinanceRedirectDialogOpen(false))}
          title={t("ACTIVE_FINANCE_TITLE")}
          message={activeFinanceResponse.message}
          buttonText={t("ACTIVE_FINANCE_BUTTON")}
          onButtonClick={() =>
            activeFinanceResponse.result &&
            history.push(`user/my-application/${activeFinanceResponse.result}`)
          }
          icon={AssignmentLateOutlinedIcon}
          color="warning"
        />
      )}

      {/* IF Agent Add Member then that dialog open */}

      {isAddMemberDialogOpen && (
        <CustomDialog
          open={isAddMemberDialogOpen}
          onClose={() => dispatch(setIsAddMemberDialogOpen(false))}
          content={{
            title: t("ADD_MEMBER"),
            component: <AddMemberStepper isUseForFinance={true} />,
          }}
        />
      )}
    </>
  );
}
