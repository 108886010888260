import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import { getAccessToken } from "../auth/services/jwtService/jwtService";

// Define the max number of retries and the delay between retries (in milliseconds)
const MAX_RETRIES = 5;
const RETRY_DELAY = 3000;

let connection;

try {
  connection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SIGNAL_R_HUB_URL, {
      accessTokenFactory: () => getAccessToken(),
      transport: HttpTransportType.WebSockets // Force the use of WebSockets
    })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();
} catch (error) {
  console.error("Error creating SignalR connection:", error);
  // Handle the error appropriately here
}

export default connection;

// Function to manage connection logic (start, retry, etc.)
export const manageSignalRConnection = async (connection) => {
  if (!connection) {
    console.error("No connection instance provided.");
    return;
  }

  let retryCount = 0;

  while (retryCount < MAX_RETRIES) {
    try {
      await connection.start();
      break;
    } catch (error) {
      retryCount++;
      console.error(
        `Connection attempt ${retryCount} failed. Retrying in ${RETRY_DELAY}ms...`
      );
      if (retryCount >= MAX_RETRIES) {
        console.error("Max retries reached. Connection failed.");
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
    }
  }
};
