const locale = {
  AGREE: "Agree",
  DISAGREE: "Disagree",
  VERIFY_OTP: "Verification",
  VIDEO_ENDED_MESSAGE: "After Completing Video you can submit your application.",
  SIGNATURE: "Signature",
  SIGNATURE_INFO: "Please sign digitally to confirm the accuracy of your information and proceed with submission.",
};

export default locale;
